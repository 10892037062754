@import './utils/variables';

body{
    font-size: 20px;
    background-color: #F4F5F9;
    direction: rtl;
}

*{
    font-weight: normal;
    font-family: $font;
}