@import '../../utils/variables';

.profile-management{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    margin-top: 5%;
    .navigation{
        display: flex;
        align-items: center;
        width: auto;
        position: absolute;
        top: 5%;
        left: 2.5%;
        button{
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    
        #navigate {
            display: flex;
            margin-right: auto;
        }
    }

    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        form{
            display: flex;
            flex-direction: column;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 2.5%;
            width: 100%;

            .input{
                display: flex;
                flex-direction: column;
                width: 15%;
                margin-top: 50px;

                label{
                    margin-bottom: 10px;
                }
                input{
                    border-radius: 10px;
                    border: none;
                    box-shadow: $shadow;
                    padding: 17px;
                }
            }

            span{
                margin-top: 4%;
                margin-bottom: 4%;
            }

            .buttons{
                display: flex;
                margin-top: 5%;
                gap: 60px;

                button{
                    border: none;
                    border-radius: 10px;
                    padding: 12px 50px;
                    cursor: pointer;
                    font-size: 21px;
                    box-shadow: $shadow;
                }

                #save{
                    background-color: black;
                    color: white;
                }

                #reset{
                    background-color: $secondary;
                    border: 3px solid black;
                    color: black;
                }
            }
        }
    }

}

@media screen and (max-width: 768px){
    .profile-management{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 60vh;
        margin-top: 5%;
        .navigation{
            display: flex;
            align-items: center;
            width: auto;
            position: absolute;
            top: 6%;
            left: 2.5%;
            button{
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
        
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }
    
        .container{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 15%;

            h1{
                font-size: 28px;
                color: $primary;
            }

            form{
                display: flex;
                flex-direction: column;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin-top: 2.5%;
                width: 100%;
    
                .input{
                    display: flex;
                    flex-direction: column;
                    width: 80%;
                    margin-top: 50px;
    
                    label{
                        margin-bottom: 10px;
                    }
                    input{
                        border-radius: 10px;
                        border: none;
                        box-shadow: $shadow;
                        padding: 17px;
                    }
                }
    
                span{
                    display: flex;
                    flex-direction: column;
                    text-align: center;
                    margin-top: 10%;
                    font-size: 16px;
                    width: 90%;
                    margin-bottom: 4%;
                }
    
                .buttons{
                    display: flex;
                    margin-top: 5%;
                    gap: 30px;
    
                    button{
                        border: none;
                        border-radius: 10px;
                        padding: 10px 40px;
                        cursor: pointer;
                        font-size: 18px;
                        box-shadow: $shadow;
                    }
    
                    #save{
                        background-color: black;
                        color: white;
                    }
    
                    #reset{
                        background-color: $secondary;
                        border: 3px solid black;
                        color: black;
                    }
                }
            }
        }
    
    }
}