@import '../utils/variables';

.Chapters {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondary;
    padding: 2.5% 0px;

    .navigation {
        display: flex;
        align-items: center;
        width: 100%;
        
        button {
            margin: 0% 2%;
            cursor: pointer;
            border: none;
            background-color: transparent;
        }

        #add {
            display: flex;
            margin-left: auto;
        }

        #navigate {
            display: flex;
            margin-right: auto;
        }
    }

    h1 {
        font-weight: normal;
    }

    .video-container {
        background-color: black;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        video {
            width: 25%;
            aspect-ratio: 16 / 9;
            object-fit: cover;
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        margin: 0% 10%;
        h3 {
            margin-bottom: 20px;
        }

        .chapters-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .chapters-row {
                display: flex;
                flex-wrap: wrap;
                gap: 2.5%;
                width: 100%;
            }
        }

        #notfound{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .notfound-main{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        
        h3 {
            margin-bottom: 20px;
            margin-left: auto;
            margin-right: 10%;
        }
    }
}

@media screen and (max-width: 768px) {
    .Chapters {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $secondary;
        padding: 2.5% 0px;
    
        .navigation {
            display: flex;
            align-items: center;
            margin-top: 10%;
            width: 100%;
            
            button {
                margin: 0% 2%;
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
    
            #add {
                display: flex;
                margin-left: auto;
            }
    
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }
    
        h1 {
            font-weight: normal;
            font-size: 28px;
        }
    
        .video-container {
            background-color: black;
            width: 95%;
            border-radius: 10px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
        
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
    
        .main {
            display: flex;
            flex-direction: column;
            
            h3 {
                margin-bottom: 20px;
            }
    
            .chapters-section {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
    
                .chapters-row {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 2.5%;
                    width: 100%;
                }
            }
    
            #notfound{
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    
        .notfound-main{
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
            
            h3 {
                margin-bottom: 20px;
                margin-left: auto;
                margin-right: 10%;
            }
        }
    }
}
