@import './variables';

.not-allowed{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: $primary;
    color: $secondary;
    .content{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin-top: 5%;
        img{
            width: 25%;
            border-radius: 20px;
        }
    
        span{
            text-align: center;
            width: 40%;
            font-size: 28px;
            margin-top: 20px;
            color: $secondary;
        }
    }
    
}

@media screen and (max-width: 768px){
    .not-allowed{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100vh;
        background-color: $primary;
        color: $secondary;
    
        .content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin-top: 50%;
            
            img{
                width: 50%;
                border-radius: 20px;
            }
        
            span{
                text-align: center;
                width: 75%;
                font-size: 20px;
                margin-top: 20px;
                color: $secondary;
            }
        }
    }
}