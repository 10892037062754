@import './variables';

.edumeme-options-modal {
    position: absolute;
    top: 15%;
    left: 0%;
    background: #272727;
    border: none;
    border-radius: 7px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 99999;
    min-width: 200px;
    max-width: 200px;
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;       
        display: flex;
        align-items: center; 
        flex-direction: column;
        justify-content: center;

        li {
            cursor: pointer;
            color: $secondary;
            width: 100%;
            height: 15px;
            padding: 10px 0px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $secondary;

            &:first-child{
                &:hover{
                    border-top-left-radius: 7px;
                    border-top-right-radius: 7px;
                }
            }
            &:last-child{
                border-bottom: none;
                background-color: #333333;
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;

                &:hover{
                    border-top-left-radius: 7px;
                    border-top-right-radius: 7px;
                }
            }

            span{
                font-size: 12px;
                margin-right: 10px;
            }
            svg{
                display: flex;
                margin-right: auto;
                margin-left: 10px;
            }
            &:hover {
                background: lighten($color: #272727, $amount: 10%);
            }
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        .modal {
            background-color: $secondary;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            max-width: 30%;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .modal-input{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 10%;
                label{
                    display: flex;
                    margin-left: auto;
                    margin-right: 37%;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    margin-top: 15px;
                    margin-bottom: 30px;
                }
                
                input {
                    border: none;
                    width: 75%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    font-size: 14px;
                    text-align: center;

                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: $shadow;
                }
            }
            
            
            .modal-buttons {
                margin-top: 5%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 5%;
                button {
                    border-radius: 10px;
                    padding: 10px 35px;
                    font-family: $font;
                    font-weight: normal;
                    font-size: 18px;
                    cursor: pointer;
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                    margin: 0px 30px;
                    position: relative;
                }
        
                #modal-submit-button {
                    background-color: black;
                    color: $secondary;
                    border: 3px solid black;
                }
        
                #modal-reset-button {
                    background-color: $secondary;
                    color: black;
                    border: 3px solid black;
                }
            }
        }
    }   
}
