@import '../utils/variables';

.subjects{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondary;
    padding: 2.5%;
    .navigation{
        display: flex;
        align-items: center;
        width: 100%;
        button{
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    
        #add{
            display: flex;
            margin-left: auto;
        }
    
        #navigate {
            display: flex;
            margin-right: auto;
        }
    }

    .main{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        h1{
            font-family: $font;
            font-weight: normal;
        }

        span{
            text-align: center;
        }

        .levels {
            background-color: white;
            direction: rtl;
            padding: 15px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            width: 20%;
            box-shadow: $shadow;
            cursor: pointer;
            position: relative;
        
            span {
                font-family: $font;
                font-size: 14px;
            }
        
            button {
                display: flex;
                margin-right: auto;
                border: none;
                background-color: transparent;
                cursor: pointer;
            }
        
            .dropdown {
                position: absolute;
                top: 100%;
                left: 0;
                background-color: white;
                border: 1px solid #ccc;
                width: 100%;
                z-index: 10;
                max-height: 200px;
                overflow-y: auto;
                border-radius: 10px;
                box-shadow: $shadow;
                scrollbar-width: thin; /* "auto" or "thin" depending on your preference */
                scrollbar-color: $primary $secondary; /* Customize the scrollbar colors */
                
                &::-webkit-scrollbar {
                    width: 8px; /* Set the width of the scrollbar */
                }
                
                &::-webkit-scrollbar-track {
                    background: $secondary; /* Set the background color of the scrollbar track */
                }
                
                &::-webkit-scrollbar-thumb {
                    background-color: $primary; /* Set the color of the scrollbar thumb */
                    border-radius: 10px; /* Optionally, round the scrollbar thumb */
                }
            }
        
            .dropdown .dropdown-item {
                padding: 15px 0px;
                cursor: pointer;
                font-family: $font;
                font-size: 14px;
                text-align: center;
                
                &:nth-child(odd) {
                    background-color: $primary;
                    color: $secondary;

                    &:hover {
                        background-color: lighten($primary, 20%);
                        color: lighten($secondary, 20%);
                    }
                }
        
                &:nth-child(even) {
                    background-color: $secondary;
                    color: $primary;

                    &:hover {
                        background-color: lighten($secondary, 20%);
                        color: lighten($primary, 20%);
                    }
                }
            }
        }        

        .subjects-section{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 2%;
            h2{
                margin: 0;
            }

            .available-subjects{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 80%;
                margin-bottom: 5%;
                .subject-elements{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5%;
                }
            }

            .not-available-subjects{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: 80%;
                h2{
                    &.animate-subject {
                        animation: popOutSubject 0.5s ease-in-out; // Adjust duration as needed
                    }
                }
                .subject-elements{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .subjects{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $secondary;
        padding: 2.5%;
        .navigation{
            display: flex;
            align-items: center;
            margin-top: 10%;
            width: 100%;
            button{
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
        
            #add{
                display: flex;
                margin-left: auto;
            }
        
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }
    
        .main{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            h1{
                font-family: $font;
                font-weight: normal;
            }
    
            .levels {
                background-color: white;
                direction: rtl;
                padding: 15px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                width: 70%;
                box-shadow: $shadow;
                cursor: pointer;
                position: relative;
            
                span {
                    font-family: $font;
                    font-size: 14px;
                }
            
                button {
                    display: flex;
                    margin-right: auto;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                }
            
                .dropdown {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    background-color: white;
                    border: 1px solid #ccc;
                    width: 100%;
                    z-index: 10;
                    max-height: 200px;
                    overflow-y: auto;
                    border-radius: 10px;
                    box-shadow: $shadow;
                    scrollbar-width: thin; /* "auto" or "thin" depending on your preference */
                    scrollbar-color: $primary $secondary; /* Customize the scrollbar colors */
                    
                    &::-webkit-scrollbar {
                        width: 8px; /* Set the width of the scrollbar */
                    }
                    
                    &::-webkit-scrollbar-track {
                        background: $secondary; /* Set the background color of the scrollbar track */
                    }
                    
                    &::-webkit-scrollbar-thumb {
                        background-color: $primary; /* Set the color of the scrollbar thumb */
                        border-radius: 10px; /* Optionally, round the scrollbar thumb */
                    }
                }
            
                .dropdown .dropdown-item {
                    padding: 15px 0px;
                    cursor: pointer;
                    font-family: $font;
                    font-size: 14px;
                    text-align: center;
                    
                    &:nth-child(odd) {
                        background-color: $primary;
                        color: $secondary;
    
                        &:hover {
                            background-color: lighten($primary, 20%);
                            color: lighten($secondary, 20%);
                        }
                    }
            
                    &:nth-child(even) {
                        background-color: $secondary;
                        color: $primary;
    
                        &:hover {
                            background-color: lighten($secondary, 20%);
                            color: lighten($primary, 20%);
                        }
                    }
                }
            }        
    
            .subjects-section{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 4%;
                margin-right: 0%;
                width: 75%;
    
                .available-subjects{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .subject-elements{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: baseline;
                        gap: 12.5%;
                    }
                }
                
                h2{
                    font-size: 16px;
                }

                .not-available-subjects{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .subject-elements{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: baseline;
                        gap: 5%;
                    }
                }
            }
        }
    }
}   

@keyframes popOutSubject {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}