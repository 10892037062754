@import '../../utils/variables';

.AboutUs{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    .intro{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 2% 0% 5% 0%;

        img{
            width: 15%;
        }

        h1{
            text-align: center;
            font-size: 32px;
            width: 50%;
            color: black;
        }
    }

    h1{
        color: $primary;
    }

    .about{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        flex: 100%;
        margin: 5% 0%;
        gap: 20%;

        .text{
            flex: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            span{
                color: $primary;
                font-size: 28px;
            }

            p{
                width: 95%;
                font-size: 30px;
            }

        }

        .meme{
            flex: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            span{
                margin-top: 2%;
                font-size: 16px;
            }

            img{
                width: 70%;
            }

            video{
                width: 600px;
                height: 340px;
                border-radius: 5px;
                object-fit: cover;
                background-color: black;
            }
        }
    }
    .main-goal{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 75%;
        flex-direction: column;
        margin-top: 5%;
        margin-bottom: 5%;
        span{
            color: $primary;
            font-size: 40px;
        }

        p{
            font-size: 28px;
            width: 55%;
            text-align: center;
        }
    }
}

@media screen and (max-width:768px) {
    .AboutUs{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
    
        .intro{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin: 15% 0% 15% 0%;
    
            img{
                width: 50%;
            }
    
            h1{
                text-align: center;
                font-size: 16px;
                width: 85%;
                color: black;
            }
        }
    
        h1{
            width: 90%;
            text-align: center;
            font-size: 22px;
            color: $primary;
        }
    
        .about{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            flex: 100%;
            margin: 5% 0%;
            gap: 20%;
    
            .text{
                order: 1; /* Ensures text comes first */
                width: 100%;
                flex: 1;
                display: flex;
                justify-content: center;
                flex-direction: column;
    
                span{
                    color: $primary;
                    font-size: 24px;
                }
    
                p{
                    width: 100%;
                    font-size: 16px;
                }
    
            }
    
            .meme{
                order: 2; /* Ensures text comes first */
                flex: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
    
                span{
                    margin-top: 2%;
                    font-size: 16px;
                    text-align: center;
                }
    
                img{
                    width: 90%;
                }
    
                video{
                    width: 75vw;
                    height: 200px;
                    border-radius: 5px;
                    object-fit: fill;
                }
            }
        }
        .main-goal{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 95%;
            flex-direction: column;
            margin-top: 5%;
            span{
                color: $primary;
                font-size: 30px;
            }
    
            p{
                font-size:18px;
                width: 100%;
                text-align: center;
            }
        }
    }
}