@import '../utils/variables';

.edumeme-management{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondary;
    padding: 2%;
    .navigation{
        display: flex;
        align-items: center;
        width: 100%;
        button{
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
        
        #navigate {
            display: flex;
            margin-right: auto;
        }
    }

    .choice{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: lightgray;
        padding: 2.5px 0px;
        border-radius: 2px;
        width: auto;

        .option{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            padding: 5px 50px;
            border-radius: 2px;
            cursor: pointer;
            width: 16.5%;
        }

        .active{
            background-color: lightgray;
        }
    }

    form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;

        .main {
        display: flex;
        width: 100%;
        margin-top: 2.5%;

        .textual {
            display: flex;
            flex-direction: column;
            flex: 50%;
            align-items: center;
            padding: 20px 0px;
            margin-top: 2.5%;
            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 80%;
                
                label {
                    font-family: $font;
                    font-weight: normal;
                    direction: rtl;
                    text-align: right;
                    margin: 0px 0px 15px 0px;
                    width: 100%;
                    margin-right: 25%;
                }

                .lessons {   
                    background-color: white;
                    direction: rtl;
                    padding: 15px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 41%;
                    box-shadow: $shadow;
                    cursor: pointer;
                    position: relative;
                    margin-bottom: 9%;
                
                    span {
                        font-family: $font;
                        font-size: 14px;
                    }
                
                    button {
                        display: flex;
                        margin-right: auto;
                        border: none;
                        width: auto;
                        padding: 0;
                        background-color: transparent;
                        cursor: pointer;
                    }
                
                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        background-color: white;
                        border: 1px solid #ccc;
                        width: 100%;
                        z-index: 10;
                        max-height: 200px;
                        overflow-y: auto;
                        border-radius: 10px;
                        box-shadow: $shadow;
                        scrollbar-width: thin; /* "auto" or "thin" depending on your preference */
                        scrollbar-color: $primary $secondary; /* Customize the scrollbar colors */
                        
                        &::-webkit-scrollbar {
                            width: 8px; /* Set the width of the scrollbar */
                        }
                        
                        &::-webkit-scrollbar-track {
                            background: $secondary; /* Set the background color of the scrollbar track */
                        }
                        
                        &::-webkit-scrollbar-thumb {
                            background-color: $primary; /* Set the color of the scrollbar thumb */
                            border-radius: 10px; /* Optionally, round the scrollbar thumb */
                        }
                    }
                
                    .dropdown .dropdown-item {
                        padding: 15px 0px;
                        cursor: pointer;
                        font-family: $font;
                        font-size: 14px;
                        text-align: center;
                        width: 100%;
                        
                        // &:nth-child(odd) {
                        //     background-color: $primary;
                        //     color: $secondary;
    
                        //     &:hover {
                        //         background-color: lighten($primary, 20%);
                        //         color: lighten($secondary, 20%);
                        //     }
                        // }
                
                        // &:nth-child(even) {
                        //     background-color: $secondary;
                        //     color: $primary;
    
                        //     &:hover {
                        //         background-color: lighten($secondary, 20%);
                        //         color: lighten($primary, 20%);
                        //     }
                        // }
                    }
                }        

                input {
                    border: none;
                    width: 43%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    margin-bottom: 9%;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                }

                textarea {
                    position: relative;
                    border: none;
                    width: 40%;
                    min-height: 140px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 20px;
                    font-family: $font;
                    resize: none;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                }

                .char-count {
                    position: absolute;
                    bottom: 10px;
                    left: 30%;
                    width: auto;
                    font-size: 12px;
                    color: #86878B;
                    font-family: $font;
                }
            }
        }

        .line {
            background-color: gray;
            width: 1.5px;
            height: 800px;
            flex: none;
            margin: 0 20px;
        }

        .media {
            flex: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0px;
            .choice-media{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                background-color: lightgray;
                padding: 2.5px 2.5px;
                border-radius: 2px;
                width: 53%;
                margin-bottom: 5%;
                .option{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: white;
                    padding: 5px 50px;
                    border-radius: 2px;
                    cursor: pointer;
                    width: 100%;
                    font-size: 18px;
                }
                .active{
                    background-color: lightgray;
                }
            }

            label {
                font-family: $font;
                font-weight: normal;
                direction: rtl;
                width: auto;
                margin: 20px 17% 30px auto;
            }

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 80%;

                input {
                    border: none;
                    width: 43%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                }

                textarea {
                    position: relative;
                    border: none;
                    width: 35%;
                    min-height: 140px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 20px;
                    font-family: $font;
                    resize: none;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                }

                .char-count {
                    position: absolute;
                    bottom: 10px;
                    left: 32%;
                    width: auto;
                    font-size: 12px;
                    color: #86878B;
                    font-family: $font;
                    font-weight: bold;
                }
            }

            .image-container {
                padding: 20px 10px;
                display: flex;
                align-items: center;
                width: 30%;
                border-radius: 10px;
                text-align: right;
                div {
                    width: 60%;
                    padding: 0px 10px 0px 0px;
                    h3 {
                    font-family: $font;
                    font-weight: normal;
                    font-size: 14px;
                    }

                    span {
                    font-family: $font;
                    font-size: 10px;
                    color: #86878B;
                    word-wrap: break-word;
                    width: 100%;
                    }
                }

                #image-input {
                    width: 100%;
                    border-radius: 10px;
                    height: 130px;
                    background-color: black;
                    color: white;
                    font-family: $font;
                    font-weight: normal;
                    cursor: pointer;
                    &::-webkit-file-upload-button {
                    visibility: hidden;
                    }
                }

                #image-uploaded{
                    width: 100%;
                    height: 130px;
                    border-radius: 10px;
                    background-color: black;
                    cursor: pointer;

                }
            }

            input {
                border: none;
                width: 43%;
                min-height: 25px;
                border-radius: 10px;
                direction: rtl;
                padding: 10px;
                font-family: $font;
                font-size: 14px;
                &::placeholder {
                color: #A7A7A7;
                font-family: $font;
                font-weight: normal;
                }
                box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
            }

            .video-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 25%;
                border-radius: 10px;
                text-align: center;
                div {
                    width: 100%;
                    padding: 0;
                    h3 {
                    font-family: $font;
                    font-weight: normal;
                    margin: 0;
                    font-size: 14px;
                    }

                    span {
                    font-family: $font;
                    font-size: 10px;
                    color: #86878B;
                    word-wrap: break-word;
                    margin: 0;
                    width: 100%;
                    }
                }

                #video-input {
                    width: 100%;
                    border-radius: 10px;
                    height: 300px;
                    background-color: black;
                    color: white;
                    font-family: $font;
                    font-weight: normal;
                    cursor: pointer;
                    &::-webkit-file-upload-button {
                    visibility: hidden;
                    }
                }

                #video-uploaded{
                    width: 100%;
                    height: 300px;
                    border-radius: 10px;
                    background-color: black;
                    cursor: pointer;
                }
            }
        }
        }

        .buttons {
        margin-top: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        button {
            border-radius: 10px;
            padding: 10px 35px;
            font-family: $font;
            font-weight: normal;
            font-size: 16px;
            cursor: pointer;
            box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
            margin: 0px 30px;
        }

        #submit-button {
            background-color: black;
            color: $secondary;
            border: 3px solid black;
        }

        #reset-button {
            background-color: $secondary;
            color: black;
            border: 3px solid black;
        }
        }
    }
}

@media screen and (max-width:768px){
    .edumeme-management{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $secondary;
        margin: 15% 0% 10% 0%;
        .navigation{
            display: flex;
            align-items: center;
            width: 100%;
            button{
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
            
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }
        h1{
            font-size: 32px;
        }
        .choice{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: lightgray;
            padding: 2.5px 0px;
            border-radius: 2px;
            width: auto;

            .option{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: white;
                padding: 5px 40px;
                border-radius: 2px;
                cursor: pointer;
                width: 16.5%;
            }

            .active{
                background-color: lightgray;
            }
        }

        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            .main {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin-top: 2.5%;

            .textual {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 20px 0px;
                margin-top: 2.5%;
                width: 90%;
                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    
                    label {
                        font-family: $font;
                        font-weight: normal;
                        direction: rtl;
                        width: auto;
                        margin: 10px 0% 15px auto;
                    }

                    .lessons {   
                        background-color: white;
                        direction: rtl;
                        padding: 15px;
                        border-radius: 10px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 75%;
                        box-shadow: $shadow;
                        cursor: pointer;
                        position: relative;
                        margin-bottom: 9%;
                    
                        span {
                            font-family: $font;
                            font-size: 14px;
                        }
                    
                        button {
                            display: flex;
                            margin-right: auto;
                            border: none;
                            width: auto;
                            padding: 0;
                            background-color: transparent;
                            cursor: pointer;
                        }
                    
                        .dropdown {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            background-color: white;
                            border: 1px solid #ccc;
                            width: 100%;
                            z-index: 10;
                            max-height: 200px;
                            overflow-y: auto;
                            border-radius: 10px;
                            box-shadow: $shadow;
                            scrollbar-width: thin; /* "auto" or "thin" depending on your preference */
                            scrollbar-color: $primary $secondary; /* Customize the scrollbar colors */
                            
                            &::-webkit-scrollbar {
                                width: 8px; /* Set the width of the scrollbar */
                            }
                            
                            &::-webkit-scrollbar-track {
                                background: $secondary; /* Set the background color of the scrollbar track */
                            }
                            
                            &::-webkit-scrollbar-thumb {
                                background-color: $primary; /* Set the color of the scrollbar thumb */
                                border-radius: 10px; /* Optionally, round the scrollbar thumb */
                            }
                        }
                    
                        .dropdown .dropdown-item {
                            padding: 15px 0px;
                            cursor: pointer;
                            font-family: $font;
                            font-size: 14px;
                            text-align: center;
                            width: 100%;
                        }
                    }        

                    input {
                        border: none;
                        width: 80%;
                        min-height: 25px;
                        border-radius: 10px;
                        direction: rtl;
                        padding: 10px;
                        font-family: $font;
                        margin-bottom: 9%;
                        font-size: 14px;
                        &::placeholder {
                        color: #A7A7A7;
                        font-family: $font;
                        font-weight: normal;
                        }
                        box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                    }

                    textarea {
                        position: relative;
                        border: none;
                        width: 80%;
                        min-height: 140px;
                        border-radius: 10px;
                        direction: rtl;
                        padding: 20px;
                        font-family: $font;
                        resize: none;
                        font-size: 14px;
                        &::placeholder {
                        color: #A7A7A7;
                        font-family: $font;
                        font-weight: normal;
                        }
                        box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                    }

                    .char-count {
                        position: absolute;
                        bottom: 10px;
                        left: 30%;
                        width: auto;
                        font-size: 12px;
                        color: #86878B;
                        font-family: $font;
                    }
                }
            }

            .line {
                display: none;
            }

            .media {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                padding: 0px;
                width: 90%;
                .choice-media{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    background-color: lightgray;
                    padding: 2.5px 2.5px;
                    border-radius: 2px;
                    width: 100%;
                    margin-bottom: 5%;
                    .option{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: white;
                        padding: 5px 20px;
                        border-radius: 2px;
                        cursor: pointer;
                        width: 100%;
                        font-size: 18px;
                    }
                    .active{
                        background-color: lightgray;
                    }
                }

                label {
                    font-family: $font;
                    font-weight: normal;
                    direction: rtl;
                    width: auto;
                    margin: 20px 5% 30px auto;
                }

                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 80%;

                    input {
                        border: none;
                        width: 43%;
                        min-height: 25px;
                        border-radius: 10px;
                        direction: rtl;
                        padding: 10px;
                        font-family: $font;
                        font-size: 14px;
                        &::placeholder {
                        color: #A7A7A7;
                        font-family: $font;
                        font-weight: normal;
                        }
                        box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                    }

                    textarea {
                        position: relative;
                        border: none;
                        width: 80%;
                        min-height: 140px;
                        border-radius: 10px;
                        direction: rtl;
                        padding: 20px;
                        font-family: $font;
                        resize: none;
                        font-size: 14px;
                        &::placeholder {
                        color: #A7A7A7;
                        font-family: $font;
                        font-weight: normal;
                        }
                        box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                    }

                    .char-count {
                        position: absolute;
                        bottom: 10px;
                        left: 5%;
                        width: auto;
                        font-size: 12px;
                        color: #86878B;
                        font-family: $font;
                        font-weight: bold;
                    }
                }

                .image-container {
                    padding: 20px 10px;
                    display: flex;
                    align-items: center;
                    width: 80%;
                    border-radius: 10px;
                    text-align: right;
                    div {
                        width: 60%;
                        padding: 0px 10px 0px 0px;
                        h3 {
                        font-family: $font;
                        font-weight: normal;
                        font-size: 14px;
                        }

                        span {
                        font-family: $font;
                        font-size: 10px;
                        color: #86878B;
                        word-wrap: break-word;
                        width: 100%;
                        }
                    }

                    #image-input {
                        width: 100%;
                        border-radius: 10px;
                        height: 130px;
                        background-color: black;
                        color: white;
                        font-family: $font;
                        font-weight: normal;
                        cursor: pointer;
                        &::-webkit-file-upload-button {
                        visibility: hidden;
                        }
                    }

                    #image-uploaded{
                        width: 100%;
                        height: 130px;
                        border-radius: 10px;
                        background-color: black;
                        cursor: pointer;

                    }
                }

                input {
                    border: none;
                    width: 70%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                }

                .video-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 75%;
                    border-radius: 10px;
                    text-align: center;
                    div {
                        width: 100%;
                        padding: 0;
                        h3 {
                        font-family: $font;
                        font-weight: normal;
                        margin: 0;
                        font-size: 14px;
                        }

                        span {
                        font-family: $font;
                        font-size: 10px;
                        color: #86878B;
                        word-wrap: break-word;
                        margin: 0;
                        width: 100%;
                        }
                    }

                    #video-input {
                        width: 100%;
                        border-radius: 10px;
                        height: 300px;
                        background-color: black;
                        color: white;
                        font-family: $font;
                        font-weight: normal;
                        cursor: pointer;
                        &::-webkit-file-upload-button {
                        visibility: hidden;
                        }
                    }

                    #video-uploaded{
                        width: 100%;
                        height: 300px;
                        border-radius: 10px;
                        background-color: black;
                        cursor: pointer;
                    }
                }
            }
            }

            .buttons {
            margin-top: 15%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                border-radius: 10px;
                padding: 10px 30px;
                font-family: $font;
                font-weight: normal;
                font-size: 16px;
                cursor: pointer;
                box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                margin: 0px 15px;
            }

            #submit-button {
                background-color: black;
                color: $secondary;
                border: 3px solid black;
            }

            #reset-button {
                background-color: $secondary;
                color: black;
                border: 3px solid black;
            }
            }
        }
    }
}