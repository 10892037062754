@import '../../utils/variables';

.MobileApp{
    display: flex;
    align-items: center;
    flex-direction: column;
    .primary-style {
        display: flex;
        background-color: $secondary;
        margin-bottom: 50px;
        align-items: center;
        justify-content: center;
        margin-top: 2%;

        .main {
            display: flex;
            align-items: center;
            justify-content: center;
            .image-container{
                display: flex;
                flex: 50%;
                align-items: center;
                justify-content: center;
                img{
                    width: 50%;    
                }
            }   
            
            .main-description{
                display: flex;
                flex-direction: column;
                align-items: center;
                flex: 50%;
                img{
                    width: 20%;
                }
                span{
                    color: black;
                    font-weight: bold;
                    margin-top: 10px;
                    font-family: $font;
                }
                h1{
                    text-align: center;
                    color: $primary;
                    font-weight: bold;
                    width: 50%;
                    margin-top: 10px;
                    font-family: $font;
                }

                .start-mobile{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5%;
                    button{
                        display: flex;
                        direction: ltr;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        color: $secondary;
                        border: none;
                        padding: 10px 10px;
                        width: 12vw;
                        margin: 2.5% 0%;
                        height: 8vh;
                        cursor: pointer;
                        
                        img{
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
                
            }
        }
    }

    .secondary-style {
        display: flex;
        background-color: $primary;
        width: 100%;
        align-items: center;
        justify-content: center;

        .aboutus{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1{
                font-family: $font;
                color: $secondary;
                margin-bottom: 80px;
                font-size: 64px;
            }
    
            .description{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                .description-style{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 100%;
                    img{
                        width: 50%;
                        margin-bottom: 10px;
                    }
                    span{
                        font-family: $font;
                        color: $secondary;
                    }
                }
            }

            .conclusion{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 2.5%;
                margin-top: 5%;
                width: 50%;
                .platform{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 100%;
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        h3{
                            color: $secondary;
                            font-family: $font;
                            text-align: center;
                            &:first-child{
                                width: 70%;
                            }

                            &:last-child{
                                width: 40%;
                            }
                        }

                        div{
                            display: flex;
                            flex-direction: row;
                            .start-mobile{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                button{
                                    display: flex;
                                    direction: ltr;
                                    align-items: center;
                                    background-color: transparent;
                                    color: $secondary;
                                    border: none;
                                    padding: 10px 20px;
                                    width: 50%;
                                    margin: 2.5% 0%;
                                    height: 7vh;
                                    cursor: pointer;
                                    
                                    img{
                                        width: 100%;
                                        object-fit: cover;
                                    }
                                }
                            }

                            .start-desktop{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-direction: column;
                                button{
                                    background-color: black;
                                    color: $secondary;
                                    border: none;
                                    border-radius: 10px;
                                    padding: 15px 20px;
                                    width: 100%;
                                    margin: 5% 0%;
                                    height: 7vh;
                                    font-size: 26px;
                                    cursor: pointer;
                                    
                                    &:last-child{
                                        background-color: transparent;
                                        color: black;
                                        border: 3px solid black;
                                    }
                                }
                            }
                        }
                    }
                    img{
                        width: 55%;
                    }
                }
            }
            .mobile-description{
                display: none;
            }
        }
    }
}

@media screen and (max-width:768px) {
    .MobileApp{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        
        .primary-style {
            display: flex;
            background-color: $secondary;
            margin-bottom: 50px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-top: 2%;
    
            .main {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                .image-container{
                    display: flex;
                    flex: 50%;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 75%;    
                    }
                }   
                
                .main-description{
                    margin-top: 20%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    flex: 50%;
                    img{
                        width: 50%;
                    }
                    span{
                        color: black;
                        font-weight: bold;
                        margin-top: 15%;
                        font-family: $font;
                    }
                    h1{
                        text-align: center;
                        color: $primary;
                        font-weight: bold;
                        width: 90%;
                        margin-top: 10px;
                        font-family: $font;
                        font-size: 32px;
                    }
    
                    .start-mobile{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 95%;
                        gap: 5%;

                        button{
                            display: flex;
                            direction: ltr;
                            align-items: center;
                            justify-content: center;
                            background-color: transparent;
                            color: $secondary;
                            border: none;
                            border-radius: 10px;
                            padding: 10px 10px;
                            width: 100%;
                            margin: 2.5% 0%;
                            cursor: pointer;
                        
                            img{
                                width: 100%;
                                object-fit: cover;
                            }
                        }
                    }
                    
                }
            }
        }
    
        .secondary-style {
            display: flex;
            background-color: $primary;
            width: 100%;
            align-items: center;
            justify-content: center;
    
            .aboutus{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h1{
                    font-family: $font;
                    color: $secondary;
                    margin-bottom: 10px;
                    text-align: center;
                    width: 80%;
                    font-size: 36px;
                }
        
                .description{
                    display: none;
                }
    
                .conclusion{
                    display: none;
                }

                .mobile-description{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin: 5% 0%;
                    .dots{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        gap: 1%;
                        .dot{
                            width: 5px;
                            height: 5px;
                            border-radius: 50%;
                            background-color: $secondary;
                            opacity: 0.6;
                            
                            &.active{
                                opacity: 1;
                                border-radius: 30%;
                                padding: 0px 3px;
                            }
                        }
                    }

                    .design{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        flex-direction: column;
                        margin: 5%;
                        
                        .description-style{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            width: 90%;

                            img{
                                width: 70%;
                                margin-bottom: 5%;
                            }
                            span{
                                color: $secondary;
                            }
                        }

                        .platform{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 90%;
                            flex-direction: column;

                            img{
                                width: 70%;
                                margin-bottom: 5%;
                            }
                            div{
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: center;

                                h3{
                                    color: $secondary;
                                    text-align: center;
                                    width: 90%;
                                }

                                .start-mobile{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;

                                    button{
                                        background-color: black;
                                        color: $secondary;
                                        display: flex;
                                        align-items: center;
                                        flex-direction: row-reverse;
                                        width: 50%;
                                        height: 8vh;
                                        font-size: 10px;
                                        border: none;
                                        border-radius: 10px;
                                        margin: 2% 0%;
                                        padding: 10px 0px;
                                        
                                        img{
                                            width: 100%;
                                            object-fit: cover;
                                        }
                                    }
                                }

                                .start-desktop{
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;

                                    button{
                                        background-color: black;
                                        color: $secondary;
                                        border: none;
                                        border-radius: 10px;
                                        width: 50%;
                                        padding: 13px;
                                        margin: 2% 0%;
                                        font-size: 20px;

                                        &:last-child{
                                            border: 3px solid black;
                                            color: black;
                                            background-color: transparent;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}