@import './variables';

.Info {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $secondary;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
        margin-bottom: 1rem;
        text-align: center;
    }

    span {
        text-align: center;
        color: #534E4E;
        width: 70%;
        margin-bottom: 1.5rem;
    }

    img {
        width: 40%;
        margin-bottom: 1.5rem;
    }

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: transparent;
        cursor: pointer;

        img {
            width: 35%;
        }
    }
}

// Add an overlay to dim the background
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 105vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999; // Just below the modal
}

@media screen and (max-width: 768px){
    .Info {
        width: 75%;
    
        h1 {
            margin-bottom: 1rem;
            text-align: center;
            font-size: 28px;
        }
    
        span {
            text-align: center;
            color: #534E4E;
            width: 90%;
            margin-bottom: 1.5rem;
            font-size: 14px;
        }
    
        img {
            width: 80%;
            margin-bottom: 1.5rem;
        }
    
        button {
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: transparent;
            cursor: pointer;
    
            img {
                width: 40%;
            }
        }
    }
}
