@import '../../utils/variables';

.Profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 2%;
    .navigation {
        display: flex;
        align-items: center;
        width: 100%;
        
        button {
            margin: 0% 2%;
            cursor: pointer;
            border: none;
            background-color: transparent;
        }

        #settings {
            display: flex;
            margin-left: auto;
        }

        #navigate {
            display: flex;
            margin-right: auto;
        }
    }
    h1{
        margin: 0;
    }
    h2{
        margin: 0;
        margin-top: 20px;
        color: $primary;
    }

    #username{
        margin: 5px;
        color: #a2cbf1;
    }

    h5{
        margin: 5px;
        font-size: 28px;
        text-align: center;
        width: 80%;
    }
    .main{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;

        .headers{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            .buttons{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 60%;
                position: relative;
                margin-top: 5%;
                div{
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    align-items: center;
                    width: 60%;
                    .active{
                        width: 60%;
                        margin: 0;
                        border: 2px solid black;
                        border-radius: 50px;
                        position: absolute;
                        top: 132.5%;
                    }
                    button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        width: 60%;
                        border: none;
                        cursor: pointer;
                    }
    
                    &:first-child{
                        margin-left: auto;
                    }
                }
                
            }
    
            hr{
                width: 80%;
                border: 1px solid black;
            }
        }
        
        .main-section{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            .saved{
                display: flex;
                flex-direction: column;
                width: 80%;

                .subject-section{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .edumemes{
                        display: flex;
                        flex-wrap: wrap; 
                        justify-content: flex-start;
                        gap: 2.05%;
                        width: 100%;
                        h2{
                            color: white;
                        }
                    }
                }
                
            }

            .progress-container{
                width: 65%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                gap: 2%;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .Profile{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .navigation {
            display: flex;
            align-items: center;
            margin-top: 10%;
            width: 100%;
            
            button {
                margin: 0% 2%;
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
    
            #settings {
                display: flex;
                margin-left: auto;
            }
    
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }
        h1{
            margin: 0;
        }
        h2{
            margin: 0;
            margin-top: 20px;
            color: $primary;
        }
    
        .main{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
    
            .headers{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                .buttons{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 90%;
                    position: relative;
                    margin-top: 5%;
                    div{
                        display: flex;
                        flex-direction: column;
                        position: relative;
                        align-items: center;
                        width: 60%;
                        .active{
                            width: 100%;
                            margin: 0;
                            border: 1px solid black;
                            border-radius: 50px;
                            position: absolute;
                            top: 132.5%;
                        }
                        button{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color: transparent;
                            width: 60%;
                            border: none;
                            cursor: pointer;
                        }
        
                        &:first-child{
                            margin-left: auto;
                        }
                    }
                    
                }
        
                hr{
                    border: none;
                }
            }
            
            .main-section{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
    
                .saved{
                    display: flex;
                    flex-direction: column;
                    width: 90%;
    
                    .subject-section{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        h2{
                            margin-bottom: 10px;
                        }
                        .edumemes{
                            display: flex;
                            flex-wrap: wrap; 
                            justify-content: flex-start;
                            gap: 0.5%;
                            width: 100%;
                            h2{
                                color: white;
                            }
                        }
                    }
                    
                }
    
                .progress-container{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    gap: 2%;
                }
            }
        }
    }    
}