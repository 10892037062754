@import '../../utils/variables';

.EditManagement{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    margin-top: 5%;
    
    .navigation{
        display: flex;
        align-items: center;
        width: auto;
        position: absolute;
        top: 5%;
        left: 2.5%;
        button{
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    
        #navigate {
            display: flex;
            margin-right: auto;
        }
    }

    .options {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px; 
        align-items: center;
        justify-content: center;
        width: 60%;
        margin-top: 3%;
        button {
            background-color: white;
            box-shadow: $shadow;
            width: 100%;
            padding: 20px;
            border: none;
            border-radius: 20px;
            font-size: 18px;
            cursor: pointer;                        
        }
    }

    #delete-account{
            background-color: white;
            box-shadow: $shadow;
            width: 25%;
            padding: 20px;
            border: none;
            margin-top: 2.5%;
            border-radius: 20px;
            font-size: 18px;
            cursor: pointer;   
    }
}

@media screen and (max-width: 768px){
    .EditManagement{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 60vh;
        margin-top: 5%;
    
        .navigation{
            display: flex;
            align-items: center;
            width: auto;
            position: absolute;
            top: 6%;
            left: 2.5%;
            button{
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
        
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }

        h1{
            margin-top: 20%;
        }

        .options {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            margin-top: 5%;
        }
        #delete-account{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 90%;
            margin-top: 20%;  
        }
    }
}