@import './variables';

.edumeme-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-image: url('../../utils/edu_bg.png');
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    width: 14.95%; 
    aspect-ratio: 9/16;
    position: relative;
    margin-top: 20px;

    cursor: pointer;
    &:last-child{
        margin-left: 0px;
    }

    button {
        background-color: transparent;
        border: none;
        position: absolute;
        top: 3%; 
        left: 3%;
        cursor: pointer;
    }

    h2 {
        text-align: center;
        color: $secondary;
        font-size: 16px;
        margin: 0px 5px 2.5px 5px;
        width: 90%;
    }

    span {
        color: #d3d3d3;
        font-size: 14px;
        font-weight: lighter;
        width: 90%;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .edumeme-card {
        width: 31.5%; 
        margin: 5px 2px;

        margin-top: 2px;
        cursor: pointer;
        &:last-child{
            margin-left: 0px;
        }
    
        button {
            background-color: transparent;
            border: none;
            position: absolute;
            top: 3%; 
            left: 3%;
            cursor: pointer;
        }
    
        h2 {
            text-align: center;
            color: $secondary;
            font-size: 12px;
            margin: 0px 5px 2.5px 5px;
        }
    
        span {
            color: $secondary;
            font-size: 11px;
            color: #d3d3d3;
            font-weight: lighter;
            text-align: center;
        }
    }    
}
