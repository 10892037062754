@import './variables';

.Navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $secondary; 
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
    animation: slide-in 1.5s forwards;
    &.closing {
        animation: slide-out 1s forwards;
    }
    #close-navbar{
        position: absolute;
        top: 5%;
        left: 2.5%;
        cursor: pointer;
        background-color: transparent;
        border: none;
        width: auto;
    }

    .navbar-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a{
            width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            #logo {
                width: 250px;
                margin-bottom: 20%;
            }
        }

        .administration{
            background-color: white;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 20px;
            cursor: pointer;
            box-shadow: $shadow;

            img{
                width: 35%;
            }

            h3{
                font-weight: normal;
                margin: 15px 0px;
            }
        }

        .topics{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border-radius: 20px;
            flex-direction: row;
            padding: 0;

            .card{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                box-shadow: $shadow;
                border-radius: 20px;
                background-color: white;
                padding: 0px 20px;
                margin-top: 30px;
                cursor: pointer;
                margin-right: 20px;
                gap: 10px;
                flex: 1 1 33.33%;
                
                &:first-child{
                    margin-right: 0;
                }

                img{
                    display: flex;
                    align-items: center;
                    width: 128px;
                }
                h4{
                    font-weight: normal;
                    margin: 10px 0px;
                    font-size: 22px;
                }
            }
        }
        
    }

    .modalLogout-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
        
        .modal {
            background-color: white;
            padding: 15px 0px;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: auto;
            padding: 20px 20px;
            img{
                width: 270px;
                height: 270px;
            }

            h2{
                color: $primary;
                margin: 0;
                margin-top: 10px;
                margin-bottom: 20px;
            }

            .logout-buttons{
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 40px;
                button{
                    padding: 4px 15px;
                    width: 60px;
                    font-size: 21px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    
                    &:first-child{
                        background-color: $primary;
                        color: white;
                        border: 3.5px solid $primary;
                        border-radius: 5px;
                    }

                    &:last-child{
                        background-color: $secondary;
                        color: $primary;
                        border: 3.5px solid $primary;
                        border-radius: 5px;
                    }
                }
            }
        }
    }   
}

@media screen and (max-width: 768px) {
    .Navbar {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $secondary; 
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-100%);
        animation: slide-in 1.5s forwards;
        &.closing {
            animation: slide-out 1s forwards;
        }
        #close-navbar{
            position: absolute;
            top: 5%;
            left: 2.5%;
            cursor: pointer;
            background-color: transparent;
            border: none;
            width: auto;
        }
    
        .navbar-main {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
    
            a{
                width: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                #logo {
                    width: 200px;
                    margin-bottom: 20%;
                }
            }
    
            .administration{
                background-color: white;
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                border-radius: 20px;
                cursor: pointer;
                box-shadow: $shadow;
                img{
                    width: 40%;
                }
    
                h3{
                    font-weight: normal;
                    margin: 15px 0px;
                }
            }
    
            .topics {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 90%;
                border-radius: 20px;
                padding: 0;
                margin-top: 0;
                margin-right: 0;
                gap: 5%;
                
                .card {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    box-shadow: $shadow;
                    border-radius: 10px;
                    background-color: white;
                    margin-top: 10%;
                    cursor: pointer;
                    flex: 1 1 33.33%;

                    &:nth-child(1n) { 
                        margin-right: 0;
                    }
            
                    img {
                        display: flex;
                        align-items: center;
                        width: 50px;
                    }
            
                    h4 {
                        font-weight: normal;
                        margin: 10px 0px;
                        font-size: 16px;
                    }
                }
            }
            
            
        }
    
        .modalLogout-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
            
            .modal {
                background-color: white;
                padding: 15px 0px;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: auto;
                padding: 20px 20px;
                img{
                    width: 270px;
                    height: 270px;
                }
    
                h2{
                    color: $primary;
                    margin: 0;
                    margin-top: 10px;
                    margin-bottom: 20px;
                }
    
                .logout-buttons{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 40px;
                    button{
                        padding: 4px 15px;
                        width: 60px;
                        font-size: 21px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        
                        &:first-child{
                            background-color: $primary;
                            color: white;
                            border: 3.5px solid $primary;
                            border-radius: 5px;
                        }
    
                        &:last-child{
                            background-color: $secondary;
                            color: $primary;
                            border: 3.5px solid $primary;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }   
    }    
}   

@keyframes slide-in {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
