@import './variables';

.Footer{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background-color: white;
    flex-direction: row-reverse;
    padding: 15px 0px;

    .footer-contact-us{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        margin: 1% 0%;

        span{
            color: $primary;
        }
        .icons{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;

            a{
                margin-top: 3.5%;
                background-color: $primary;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 5%;
                aspect-ratio: 7/5;
                margin-right: 30px;
                cursor: pointer;
                &:first-child{
                    margin-right: 0px;
                }

                svg{
                    font-size: 28px;
                }
            }
        }
    }

    .footer-main{
        display: flex;
        justify-content: center;
        width: 33.33%;
        margin: 1% 0%;

        img {
            width: 20%;
        
            &:first-child {
                transform: scaleX(-1); // Flips the image horizontally
            }
        }        

        .footer-content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 0% 5%;

            span{
                color: black;
                margin: 4% 0%;
                &:first-child{
                    color: $primary;
                }
            }

            #aboutus {
                cursor: pointer;
                position: relative; // Required for the pseudo-element positioning
              
                &:hover {
                  color: $primary;
                  
                  &::after {
                    width: 100%;
                  }
                }
              
                &::after {
                  content: '';
                  position: absolute;
                  left: 0;
                  bottom: -2px; // Adjust depending on the text's baseline and desired position
                  width: 0;
                  height: 2px; // Thickness of the underline
                  background-color: $primary; // Same as the hover color
                  transition: width 0.3s ease; // Smooth transition
                }
              }              

            p{
                margin: 4% 0%;                
            }
        }
    } 

    .footer-apps{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 33.33%;
        margin: 1% 0%;

        span{
            color: $primary;
        }

        .footer-buttons{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 70%;

            span{
                color: $secondary;
            }

            button{
                display: flex;
                direction: ltr;
                align-items: center;
                background-color: transparent;
                color: $secondary;
                border: none;
                padding: 10px 5px;
                width: 75%;
                margin: 5% 2.5%;
                height: 6vh;
                cursor: pointer;

                img{
                    width: 100%;
                    object-fit: cover;
                }
            }
        }

        p{
            display: none;
        }
    }
}

@media screen and (max-width: 768px){
    .Footer{
        flex-direction: column;   
        align-items: center;
        text-align: center;
        width: 100%;     
        padding: 30px 0px;
        .footer-contact-us{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            margin: 1% 0%;
    
            span{
                color: $primary;
            }
            .icons{
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 100%;
                margin: 5% 0%;
                a{
                    margin-top: 3.5%;
                    background-color: $primary;
                    padding: 10px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 7.5%;
                    aspect-ratio: 7/5;
                    margin-right: 15px;
                    cursor: pointer;
                    &:first-child{
                        margin-right: 0px;
                    }
    
                    svg{
                        font-size: 20px;
                    }
                }
            }
        }
    
        .footer-main{
            display: flex;
            justify-content: center;
            width: 90%;
            margin: 5% 0%;
            
            img {
                width: 30%;
            
                &:first-child {
                    transform: scaleX(-1); // Flips the image horizontally
                }
            }        
    
            .footer-content{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin: 0% 10%;
                span{
                    color: black;
                    margin: 4% 0%;
                    font-size: 14px;
                    &:first-child{
                        color: $primary;
                    }
                }
                p{
                    display: none;
                    margin: 4% 0%;    
                    font-size: 14px;            
                }
            }
        } 
    
        .footer-apps{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 90%;
            margin: 1% 0%;
    
            span{
                color: $primary;
            }
    
            .footer-buttons{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                span{
                    color: $secondary;
                }
    
                button{
                    display: flex;
                    direction: ltr;
                    align-items: center;
                    background-color: black;
                    color: $secondary;
                    border: none;
                    border-radius: 10px;
                    padding: 10px 5px;
                    width: 75%;
                    margin: 5% 2.5%;
                    height: 7vh;
    
                    cursor: pointer;
                    .icon{
                        display: flex;
                        width: 20%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        svg{
                            height: 24px;
                        }
                    }
                    .text{
                        display: flex;
                        justify-content: center;
                        height: 100%;
                        flex-direction: column;
                        width: 100%;
                        margin-left: 5%;
                        font-size: 11px;
                        span{
                            font-size: 14px;
                            font-weight: bold;
                        }
                    }
                }
            }

            p{  
                display: flex   ;
                font-size: 15px;
                margin: 5%;
            }
        }
    }
}