// theme colors
$primary: #2478DC !default;
$secondary: #F4F5F9 !default;
$delete: rgba(255, 0, 0, 0.8);

@font-face {
    font-family: 'VEXA';
    src: url('../fonts/VEXA-.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.ready{
    position: absolute;
    top: 10%;
    left: 2%;
    color: $primary;
    font-size: 14px;
}

.ready-edumeme{
    position: absolute;
    top: 3%;
    left: 3%;
    color: $secondary;
    font-size: 14px;
}

$font: 'VEXA', sans-serif;
$shadow : -3px 6px 5px 0px rgba(0, 0, 0, 0.2);

h1, h2, h3, h4, h5, h6{
    margin: 20px 0px;
}
