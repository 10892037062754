@import 'variables';

.progress-card{
    margin-top: 5%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 25px;
    padding: 1% 2%;
    width: 40%;
    cursor: pointer;
    box-shadow: $shadow;

    .details{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        margin: 0;
        .subject{
            display: flex;
            align-items: center;
            margin: 0;
            width: auto;
            img{
                width: 35%;
                border-radius: 15px ;
            }

            h2{
                margin-right: 2.5%;
                font-size: 28px;
            }
        }

        .value{
            display: flex;
            align-items: center;
            margin-top: 3%;
            margin-left: auto;
            width: 100%;
            p{
                width: 100%;
                font-size: 24px;         
                margin: 0;   
                margin-right: 20px;    
                span{
                    margin: 0;
                    padding: 0;
                    color: $primary;
                    font-weight: bold;
                }
            }
        }
    }

    .progress {
        position: relative;
        display: flex;
        align-items: center;
        width: 30%;
        .progress-bar {
            width: 100%;
            position: relative;
        }

        .progress-image {
            position: absolute;
            top: 51%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 75%;
            img {
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .progress-card{
        margin-top: 5%;
        display: flex;
        align-items: center;
        background-color: white;
        border-radius: 25px;
        padding: 1% 2%;
        width: 90%;
        cursor: pointer;
        box-shadow: $shadow;
    
        .details{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 70%;
            margin: 0;

            .subject{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;
                width: auto;
                img{
                    width: 35%;
                    border-radius: 15px ;
                }
    
                h2{
                    width: 50%;
                    font-size: 24px;
                    margin: 0;
                    margin-right: 2%;
                }
            }
    
            .value{
                display: flex;
                align-items: center;
                margin-top: 3%;
                margin-left: auto;
                width: 100%;
                p{
                    width: 100%;
                    font-size: 20px;         
                    margin: 0;   
                    margin-right: 20px;    
                    span{
                        margin: 0;
                        padding: 0;
                        color: $primary;
                        font-weight: bold;
                    }
                }
            }
        }
    
        .progress {
            position: relative;
            display: flex;
            align-items: center;
            width: 40%;
            .progress-bar {
                width: 100%;
                position: relative;
            }
    
            .progress-image {
                position: absolute;
                top: 51%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 75%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
        }
    }
    
}

