@import './variables';

.notfound{
    display: flex;
    align-items: center;
    background-color: white;
    flex-direction: column;
    padding: 10px 10px;
    border-radius: 10px;
    img{
        width: 200px;
        padding: 0;
    }

    button{
        background-color: black;
        color: $secondary;
        margin: 5px;
        padding: 8px 30px;
        border: none;
        border-radius: 10px;
        text-align: center;
        box-shadow: $shadow;
        cursor: pointer;
    }
}