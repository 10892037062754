@import '../utils/variables';

.eduMeme {
    display: flex;
    flex: 100%;
    height: 100vh;
    overflow: hidden; /* Ensure no overflow outside viewport */

    &.slide-in-up {
        animation: slide-in-up 0.8s forwards;
    }

    &.slide-out-up {
        animation: slide-out-up 0.8s forwards;
    }

    &.slide-in-down {
        animation: slide-in-down 0.8s forwards;
    }

    &.slide-out-down {
        animation: slide-out-down 0.8s forwards;
    }

    .info-section {
        display: flex;
        flex-direction: column;
        flex: 48%;
        background-color: white;
        margin: 2.5% 2.5% 2.5% 0px;
        border-radius: 20px;
        overflow: hidden; /* Ensure no overflow from children */

        .main-section {
            display: flex;
            flex-direction: column;
            margin: 7% 13%;

            h2, h3 {
                margin: 0;
                margin-bottom: 1%;
            }
            .container{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-top: 7.5%;

                hr {
                    width: 90%;
                    border: 1.5px solid black;
                }
                
                .sections{
                    display: flex;
                    align-items: center;
                    width: 80%;
                    position: relative;
                    div{
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        flex-direction: column;

                        .active{
                            width: 50%;
                            margin: 0;
                            border: 2px solid black;
                            border-radius: 50px;
                            position: absolute;
                            top: 127.5%;
                        }

                        button{
                            background-color: transparent;
                            border: none;
                            font-size: 28px;
                            cursor: pointer;
                        }
                    }
                    
                }

                .comments-section {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    overflow: hidden;
    
                    .comments {
                        width: 100%;
                        max-height: 300px;
                        min-height: 320px;
                        overflow-y: auto;
                        margin-bottom: 10px;
    
                        scrollbar-width: thin;
                        scrollbar-color: $primary white;
    
                        &::-webkit-scrollbar {
                            width: 6px;
                            margin-right: 10px;
                        }
    
                        &::-webkit-scrollbar-track {
                            background: transparent;
                        }
    
                        &::-webkit-scrollbar-thumb {
                            background-color: black;
                            border-radius: 10px;
                            border: 1px solid white;
                        }
    
                        &::-webkit-scrollbar-button {
                            display: none;
                        }
    
                        .nocomments{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;
                            margin-top: 5%;
                            img{
                                width: 35%;
                            }
                            span{
                                margin-top: 5%;
                            }
                        }
                    }
    
                    .comment-field {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-top: auto;
                        justify-content: center;
                        margin-bottom: 10px;
                        input { 
                            background-color: $secondary;
                            border: none;
                            width: 60%;
                            padding: 2.5%;
                            border-radius: 10px;
                            margin-right: 10px;
                        }
    
                        button {
                            background-color: transparent;
                            border: none;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: auto;
                            padding: 0;
                            margin-right: 10px;
    
                            img {
                                width: 25px;
                            }
                        }
                    }
                }
                .statistical-section{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 90%;
                    min-height: 100%;
                    .this-post{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        margin-top: 5%;
                        h3{
                            margin: 0;
                        }

                        img{
                            width: 10%;
                        }

                        .options{
                            display: flex;
                            align-items: center;
                            width: 100%;
                            .container{
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                margin-top: 4%;
                                h4{
                                    margin: 0;
                                    margin-top: 5px;
                                    font-weight: bold;
                                }
                            }
                        }

                        &:last-child{
                            margin-top: auto;
                        }
                    }
                }
            }
            
        }
    }

    .interactions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 4%;
        margin: 2.5% 0%;
        gap: 4%;
        .mobile-stat{
            display: none;
        }
        .desktop-stat{
            display: flex;
        }
        .mobile-comments{
            display: none;
        }
        div { 
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;

            span {
                margin-top: 5px;
                font-size: 14px;
            }
        }

        svg {
            cursor: pointer;
        }

        .external-link{
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
            border-radius: 50%;
            padding: 10px 7px;
        }
    }

    .media-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 48%;
        border-radius: 20px;
        background-color: white;
        margin: 2.5% 0 2.5% 2.5%;
        justify-content: center;
        position: relative;
        overflow: hidden; /* Ensure no overflow */

        .mobile-details, .mobile-title{
            display: none;
        }
        
        &.media-rotate-in {
            animation: media-rotate-in 0.5s forwards;
        }

        &.media-rotate-out {
            animation: media-rotate-out 0.5s forwards;
        }

        .actions {
            position: absolute;
            left: 5%;
            top: 5%;
            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
            }
        }

        .informations {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-direction: column;
            width: 95%;
            height: auto;
            max-height: 80%;
            overflow-y: auto;
            position: relative;
            direction: ltr;
            
            &::-webkit-scrollbar {
                width: 5px;
            }

            &::-webkit-scrollbar-track {
                background: #bdbbbb;
                border-radius: 20px;
            }
        
            &::-webkit-scrollbar-thumb {
                background: black;
                border-radius: 20px;
            }
        
            &::-webkit-scrollbar-thumb:hover {
                background: $primary;
            }
        

            img {
                height: 100%;
                width: 60%;
                object-fit: scale-down;
            }

            span {
                margin-top: 20px;
                width: 75%;
                text-align: right;
            }
        }

        .meme {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: auto;

            video {
                width: 80%;
                max-height: 80%;
                border-radius: 5px;
            }
        }

        &.dark-mode {
            background-color: black;
        }
    }

    .dark-mode {
        background-color: black;
    }

    .mobile-open-card{
        display: none;
    }
}

.edumemes-scrolling{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    #navigate{
        border: none;
        background-color: transparent;
        margin: 2.5% 2.5% 0% auto;
        svg{
            font-size: 26px;
        }
    }

    .edumeme-chapter{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        margin-top: 10%;
        img{
            width: 27.5%;
            border-radius: 20px;
        }

        .chapter-details{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-right: 2.5%;
            width: 70%;
            h1{
                font-size: 28px;
                color: $primary;
                margin: 1% 0%;
            }
            span{
                font-size: 14px;
            }
        }
    }

    h3{
        color: $primary;
        margin: 0;
        margin-top: 10%;
    }

    .edumemes-scrolling-main{
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .edumeme-container{
            display: flex;
            margin-bottom: 5%;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (max-width: 768px) {
    .eduMeme {
        display: flex;
        flex: 100%;
        height: 100vh;
        overflow: auto;
        &.slide-in-up {
            animation: slide-in-up 0.8s forwards;
        }

        &.slide-out-up {
            animation: slide-out-up 0.8s forwards;
        }

        &.slide-in-down {
            animation: slide-in-down 0.8s forwards;
        }

        &.slide-out-down {
            animation: slide-out-down 0.8s forwards;
        }

        .info-section {
            display: none;
        }

        .interactions {
            position: absolute;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            bottom: 0%;
            left: 2%;
            gap: 10px;
            z-index: 9999;
            .mobile-stat{
                display: flex;
            }
            .desktop-stat{
                display: none;
            }
            .mobile-comments{
                display: flex;
            }
            .down, .up{
                display: none;
            }

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                
                span {
                    margin-top: 5px;
                    font-size: 14px;
                }
            }

            svg {
                cursor: pointer;
            }

            .external-link{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                border-radius: 50%;
                padding: 10px 7px;
            }
        }

        .media-section {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 0;
            margin: 0;

            .mobile-title{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: left;
                margin-top: 20%;
                margin-bottom: 10px;
                h2{
                    text-align: center;
                    margin: 0;
                    font-size: 22px;
                }

                h3{
                    text-align: center;
                    margin: 0;
                    font-size: 18px;
                    color: $primary;
                }
            }

            .mobile-details{
                display: flex;
                flex-direction: column;
                margin-top: auto;
                margin-left: auto;
                margin-right: 10px;
                margin-bottom: 10px;
                h2{
                    margin: 0;
                    font-size: 24px;
                }

                h3{
                    margin: 0;
                    font-size: 16px;
                    color: $primary;
                }
            }

            &.media-rotate-in {
                animation: media-rotate-in 0.5s forwards;
            }

            &.media-rotate-out {
                animation: media-rotate-out 0.5s forwards;
            }

            .actions {
                position: absolute;
                left: 5%;
                top: 6%;
                button {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                }
            }

            .informations {
                display: flex;
                flex-direction: column;
                width: 80%;
                height: auto;

                img {
                    width: 100%;
                    height: 60%;
                }

                span {
                    margin-top: 20px;
                    width: 90%;
                    font-size: 14px;
                    text-align: right;
                    margin-left: auto;

                }
            }

            .meme {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;

                video {
                    width: 100%;
                    border-radius: 5px;
                }
            }

            &.dark-mode {
                background-color: black;
            }
        }

        .dark-mode {
            background-color: black;
        }

        .overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5); // Semi-transparent background
            z-index: 9998; // One level below the mobile-open-card
        }

        .mobile-open-card{
            position: absolute;
            z-index: 9999;
            bottom: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: white;
            width: 100%;
            height: 75vh;
            border: none;
            border-radius: 10px;
            
            &.slide-in {
                animation: slideUp 0.7s ease forwards;
            }
        
            &.slide-out {
                animation: slideDown 0.7s ease forwards;
            }
            
            .line {
                border: 2px solid lightgray;
                border-radius: 10px;
                width: 10%;
                margin: 0 auto; /* To center the line */
                margin-top: 5px;
            }
            

            .comments {
                flex-grow: 1; 
                width: 90%;
                overflow-y: auto;
                padding: 10px;
                .nocomments {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                }

                img{
                    width: 50%;
                    margin-bottom: 20px;
                }
            }

            .comment-field {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                width: 95%;
            
                input {
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    border: none;
                    padding: 10px;
                    background-color: $secondary;
                    width: 100%;
                }
            
                button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    background-color: transparent;
                }
            }

            .statistical-section{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 95%;

                .this-post{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    img{
                        width: 20%;
                    }

                    h3{
                        font-size: 20px;
                    }

                    .options{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 20%;

                        .container{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            h4{
                                font-size: 16px;
                                margin: 20% 0px;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
            
        }
    }
}

@keyframes slide-in-up {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-out-up {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}

@keyframes slide-in-down {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slide-out-down {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}

@keyframes media-rotate-in {
    0% {
        transform: rotateY(90deg);
    }
    100% {
        transform: rotateY(0deg);
    }
}

@keyframes media-rotate-out {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(90deg);
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}