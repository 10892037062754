@import '../../utils/variables';

.TeamManagement{
    display: flex;
    flex-direction: column;
    background-color: $secondary;
    padding: 2.5% 0px;

    .navigation {
        display: flex;
        align-items: center;
        width: 100%;
        
        button {
            margin: 0% 2%;
            cursor: pointer;
            border: none;
            background-color: transparent;
        }

        #add {
            display: flex;
            margin-left: auto;
        }

        #navigate {
            display: flex;
            margin-right: auto;
        }
    }
}