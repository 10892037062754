@import './variables';

.comment {
    width: 100%;
    display: flex;
    margin: 3% 0;

    .comment-content {
        display: flex;
        align-items: center;
        width: 100%;

        .user {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            overflow: hidden; 
            
            img {
                width: 100%; /* Make the image fill the .user container */
                height: auto; /* Maintain aspect ratio */
                border-radius: 50%; /* Keep the circular shape */
            }
        }

        .content {
            h5 {
                font-family: Arial, Helvetica, sans-serif;
                color: $primary;
                font-size: 15px;
                margin: 0;
            }

            span {
                margin: 0;
                font-size: 14px;
            }
        }
    }

    .timespan {
        direction: ltr;
        display: flex;
        width: fit-content;
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #86878B;
        margin-right: auto;
        font-family: Arial, Helvetica, sans-serif;
        margin-left: 10px;
    }
}
