@import '../../utils/variables';

.AccountSetForRemoval{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: $secondary;
    img{
        width: 20%;
        border-radius: 20px;
        margin: 2.5% 0%;
    }

    button{
        background-color: $primary;
        color: $secondary;
        margin-top: 2%;
        padding: 15px;
        width: 20%;
        font-family: $font;
        font-weight: normal;
        cursor: pointer;
        border-radius: 10px;
        border: none;
        font-size: 18px;
    }
}

@media screen and (max-width: 768px){
    .AccountSetForRemoval{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100vh;
        background-color: $secondary;

        h1{
            font-size: 26px;
            text-align: center;
        }
        span{
            text-align: center;
            font-size: 16px;
            width: 90%;
        }
        img{
            width: 60%;
            border-radius: 20px;
            margin: 2.5% 0%;
        }
    
        button{
            background-color: $primary;
            color: $secondary;
            margin-top: 5%;
            padding: 15px;
            width: 90%;
            font-family: $font;
            font-weight: normal;
            cursor: pointer;
            border-radius: 10px;
            border: none;
            font-size: 18px;
        }
    }
}