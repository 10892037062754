@import '../utils/variables';

.edumeme{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $secondary;
    padding: 2.5%;
    .navigation{
        display: flex;
        align-items: center;
        width: 100%;
        button{
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    
        #add{
            display: flex;
            margin-left: auto;
            cursor: pointer;
        }
    
        #navigate {
            display: flex;
            margin-right: auto;
        }

        #finish{
            color: $primary;
            font-size: 18px;
        }

        #done{
            background-color: #0C993B;
            color: white;
            padding: 10px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 14px;
        }
    }

    .main{
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-top: 5%;

        .chapter-details{
            display: flex;
            align-items: center;

            img{
                width: 12%;
                border-radius: 15px;
            }
            div{
                width: 80%;
                margin-right: 2.5%;
                h2{
                    margin: 0;
                    margin-bottom: 10px;
                    color: $primary;
                }
            }
        }

        .edumeme-section{
            display: flex;
            flex-direction: column;
            margin-top: 5%;
            width: 100%;
            .edumeme-title{
                display: flex;
                align-items: center;
                h2{
                    margin: 0px 0px 1% 0px;
                }
            }
            

            hr{
                margin-left: auto;
                width: 25%;
                height: 1.5px;
                background-color: black;
                margin: 0; 
            }
            .edumemes-container{
                width: 100%;
                box-sizing: border-box;        
                margin-top: 2.5%;
                
                .edumemes-title{
                    display: flex;
                    align-items: center;
                    margin-top: 3%;
                    
                    &:first-child{
                        margin-top: 0;
                    }

                    h3{
                        font-size: 26px;
                        margin: 0;
                        color: $primary;
                    }

                    .buttons{
                        display: flex;
                        align-items: center;
                        margin-right: auto;
                        gap: 10px;
                        
                        button{
                            background-color: transparent;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                            &:first-child{
                                border: none;
                            }

                            &:last-child{
                                border: 2px solid black;
                                border-radius: 5px;
                                padding: 2px;
                            }
                        }
                        
                    }
                }

                .edumemes{
                    display: flex;
                    flex-wrap: wrap; 
                    justify-content: flex-start;
                    gap: 2.05%;
                }

                .notfound-edumemes{
                    margin-top: 2.5%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            
        }
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;

        .modal {
            background-color: $secondary;
            padding: 20px;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            max-width: 30%;
            width: 100%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h2{
                margin-bottom: 15%;
            }
            .modal-input{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                margin-bottom: 10%;
                label{
                    text-align: right;
                    margin-bottom: 15px;
                    margin-left: auto;
                    margin-right: 23%;
                }
                
                input {
                    border: none;
                    width: 50%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    font-size: 14px;
    
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: $shadow;
                }
            }
            
            
            .modal-buttons {
                margin-top: 5%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 5%;
                button {
                    border-radius: 10px;
                    padding: 10px 35px;
                    font-family: $font;
                    font-weight: normal;
                    font-size: 18px;
                    cursor: pointer;
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                    margin: 0px 30px;
                }
        
                #modal-submit-button {
                    background-color: black;
                    color: $secondary;
                    border: 3px solid black;
                }
        
                #modal-reset-button {
                    background-color: $secondary;
                    color: black;
                    border: 3px solid black;
                }
            }
        }
    }   
}

@media screen and (max-width: 768px) {
    .edumeme{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.5%;

        .navigation{
            display: flex;
            align-items: center;
            margin-top: 10%;
            width: 100%;
            button{
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
        
            #add{
                display: flex;
                margin-left: auto;
                cursor: pointer;
            }
        
            #navigate {
                display: flex;
                margin-right: auto;
            }
    
            #finish{
                color: $primary;
                font-size: 18px;
            }
    
            #done{
                background-color: #0C993B;
                color: white;
                padding: 10px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                font-size: 14px;
            }
        }
    
        .main{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 5%;
    
            .chapter-details{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                img{
                    display: none;
                }
                div{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 90%;
                    margin-right: 2.5%;
                    h2{
                        text-align: center;
                        width: 100%;
                        margin: 0;
                        margin-bottom: 10px;
                        color: $primary;
                        font-size: 24px;
                    }
                    span{
                        text-align: center;
                        width: 100%;
                        color: gray;
                        font-size: 14px;
                    }
                }
            }
    
            .edumeme-section{
                display: flex;
                flex-direction: column;
                margin-top: 5%;
                .edumeme-title{
                    display: flex;
                    align-items: center;
                    h2{
                        margin: 0px 0px 1% 0px;
                        font-size: 26px;
                    }
                }
                
    
                hr{
                    margin-left: auto;
                    border: none;
                    width: 30%;
                    height: 2px;
                    background-color: black;
                    margin: 0; 
                }
                .edumemes-container{
                    width: 100%;
                    box-sizing: border-box;        
                    margin-top: 2.5%;
                    
                    .edumemes-title{
                        display: flex;
                        align-items: center;
                        margin-top: 3%;
                        
                        &:first-child{
                            margin-top: 0;
                        }
    
                        h3{
                            font-size: 21px;
                            margin: 0;
                            color: $primary;
                            margin-bottom: 10px;
                        }
    
                        .buttons{
                            display: flex;
                            align-items: center;
                            margin-right: auto;
                            gap: 10px;
                            
                            button{
                                background-color: transparent;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                cursor: pointer;
    
                                &:first-child{
                                    border: none;
                                }
    
                                &:last-child{
                                    border: 2px solid black;
                                    border-radius: 5px;
                                    padding: 2px;
                                }
                            }
                            
                        }
                    }
    
                    .edumemes{
                        display: flex;
                        flex-wrap: wrap; 
                        justify-content: flex-start;
                        gap: 0.5%;
                    }
    
                    .notfound-edumemes{
                        margin-top: 2.5%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
                
            }
        }
    
        .modal-overlay {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1000;
    
            .modal {
                background-color: $secondary;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                max-width: 85%;
                width: 100%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h2{
                    margin-bottom: 15%;
                }
                .modal-input{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 10%;
                    label{
                        text-align: right;
                        margin-bottom: 15px;
                        margin-left: auto;
                        margin-right: 23%;
                    }
                    
                    input {
                        border: none;
                        width: 75%;
                        min-height: 25px;
                        border-radius: 10px;
                        direction: rtl;
                        padding: 10px;
                        font-family: $font;
                        font-size: 14px;
        
                        &::placeholder {
                        color: #A7A7A7;
                        font-family: $font;
                        font-weight: normal;
                        }
                        box-shadow: $shadow;
                    }
                }
                
                
                .modal-buttons {
                    margin-top: 5%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-bottom: 5%;
                    button {
                        border-radius: 10px;
                        padding: 10px 35px;
                        font-family: $font;
                        font-weight: normal;
                        font-size: 18px;
                        cursor: pointer;
                        box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                        margin: 0px 15px;
                    }
            
                    #modal-submit-button {
                        background-color: black;
                        color: $secondary;
                        border: 3px solid black;
                    }
            
                    #modal-reset-button {
                        background-color: $secondary;
                        color: black;
                        border: 3px solid black;
                    }
                }
            }
        }   
    }
}