@import '../utils/variables';

.SubjectManagement {
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;    
    .navigation{
        display: flex;
        align-items: center;
        width: auto;
        position: absolute;
        top: 2.5%;
        left: 2.5%;
        button{
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    
        #navigate {
            display: flex;
            margin-right: auto;
        }
    }

    h1 {
        font-family: $font;
        font-weight: normal;
        margin-top: 2.5%;
    }
    width: 100%;
    
    form {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;

        .subject-main {
        display: flex;
        width: 100%;
        margin-top: 2.5%;

        .textual {
            display: flex;
            flex-direction: column;
            flex: 50%;
            align-items: center;
            padding: 20px 0px;

            div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 80%;

                label {
                    font-family: $font;
                    font-weight: normal;
                    direction: rtl;
                    text-align: center;
                    margin: 0px 0px 15px 0px;
                    width: 100%;
                }

                select {
                    border: none;
                    width: 50%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    margin-bottom: 9%;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: $shadow;
                }
                .image-container {
                    padding: 20px 10px;
                    display: flex;
                    align-items: center;  
                    box-shadow: $shadow;
                    width: 25%;
                    min-height: 30%;
                    border-radius: 10px;
                    text-align: center;
                    div {
                        width: 90%;
                        padding: 0;
                        h3 {
                        font-family: $font;
                        font-weight: normal;
                        margin: 0;
                        font-size: 14px;
                        }

                        span {
                        font-family: $font;
                        font-size: 10px;
                        color: #86878B;
                        word-wrap: break-word;
                        margin: 0;
                        width: 100%;
                        }
                    }

                    #image-input {
                        width: 100%;
                        border-radius: 10px;
                        height: 120px;
                        background-color: black;
                        color: white;
                        font-family: $font;
                        font-weight: normal;
                        cursor: pointer;
                        &::-webkit-file-upload-button {
                        visibility: hidden;
                        }
                    }

                    #image-uploaded{
                        width: 100%;
                        height: 120px;
                        border-radius: 10px;
                        background-color: black;
                        cursor: pointer;

                    }
                }
            }
        }

        .line {
            background-color: black;
            width: 0.1rem;
            height: 420px;
            flex: none;
            margin: 0 20px;
        }

        .media {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;
            flex: 50%;

            label {
                font-family: $font;
                font-weight: normal;
                direction: rtl;
                text-align: center;
                margin: 0px 0px 15px 0px;
                width: auto;
            }

            input {
                border: none;
                width: 37%;
                min-height: 25px;
                border-radius: 10px;
                direction: rtl;
                padding: 10px;
                font-family: $font;
                margin-bottom: 9%;
                font-size: 14px;
                &::placeholder {
                color: #A7A7A7;
                font-family: $font;
                font-weight: normal;
                }
                box-shadow: $shadow;
            }
            .video-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40%;
                border-radius: 10px;
                text-align: center;
                div {
                    width: 100%;
                    padding: 0;
                    h3 {
                    font-family: $font;
                    font-weight: normal;
                    margin: 0;
                    font-size: 14px;
                    }

                    span {
                    font-family: $font;
                    font-size: 10px;
                    color: #86878B;
                    word-wrap: break-word;
                    margin: 0;
                    width: 100%;
                    }
                }

                #video-input {
                    width: 100%;
                    border-radius: 10px;
                    height: 140px;
                    background-color: black;
                    color: white;
                    font-family: $font;
                    font-weight: normal;
                    cursor: pointer;
                    &::-webkit-file-upload-button {
                    visibility: hidden;
                    }
                }

                #video-uploaded{
                    width: 100%;
                    height: 120px;
                    border-radius: 10px;
                    background-color: black;
                    cursor: pointer;
                }
            }
        }
        }
        .buttons {
            margin-top: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                border-radius: 10px;
                padding: 10px 35px;
                font-family: $font;
                font-weight: normal;
                font-size: 16px;
                cursor: pointer;
                box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                margin: 0px 30px;
            }

            #submit-button {
                background-color: black;
                color: $secondary;
                border: 3px solid black;
            }

            #reset-button {
                background-color: $secondary;
                color: black;
                border: 3px solid black;
            }
        }
    }
}

@media screen and (max-width:768px){
    .SubjectManagement {
        background-color: $secondary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;   
        margin: 20% 0% 10% 0%;
        .navigation{
            display: flex;
            align-items: center;
            width: auto;
            position: absolute;
            top: 5%;
            left: 2.5%;
            button{
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
        
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }
    
        h1 {
            font-family: $font;
            font-weight: normal;
            margin-top: 2.5%;
        }
        width: 100%;
        
        form {
            display: flex;
            align-items: center;
            width: 100%;
            flex-direction: column;
    
            .subject-main {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 2.5%;
    
            .textual {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 20px 0px;
                
                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 80%;
    
                    label {
                        font-family: $font;
                        font-weight: normal;
                        direction: rtl;
                        text-align: center;
                        margin: 0px 0px 15px 0px;
                        width: 100%;
                    }
    
                    select {
                        border: none;
                        width: 75%;
                        min-height: 25px;
                        border-radius: 10px;
                        direction: rtl;
                        padding: 10px;
                        font-family: $font;
                        margin-bottom: 9%;
                        font-size: 14px;

                        &::placeholder {
                        color: #A7A7A7;
                        font-family: $font;
                        font-weight: normal;
                        }
                        box-shadow: $shadow;
                    }

                    .image-container {
                        padding: 20px 10px;
                        display: flex;
                        align-items: center;  
                        box-shadow: $shadow;
                        width: 50%;
                        min-height: 30%;
                        border-radius: 10px;
                        text-align: center;
                        div {
                            width: 100%;
                            padding: 0;
                            h3 {
                            font-family: $font;
                            font-weight: normal;
                            margin: 0;
                            font-size: 14px;
                            }
    
                            span {
                            font-family: $font;
                            font-size: 10px;
                            color: #86878B;
                            word-wrap: break-word;
                            margin: 0;
                            width: 100%;
                            }
                        }
    
                        #image-input {
                            width: 100%;
                            border-radius: 10px;
                            height: 120px;
                            background-color: black;
                            color: white;
                            font-family: $font;
                            font-weight: normal;
                            cursor: pointer;
                            &::-webkit-file-upload-button {
                            visibility: hidden;
                            }
                        }
    
                        #image-uploaded{
                            width: 100%;
                            height: 120px;
                            border-radius: 10px;
                            background-color: black;
                            cursor: pointer;
    
                        }
                    }
                }
            }
    
            .line {
                display: none;
            }
    
            .media {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
    
                label {
                    font-family: $font;
                    font-weight: normal;
                    direction: rtl;
                    text-align: center;
                    margin: 0px 0px 15px 0px;
                    width: auto;
                }
    
                input {
                    border: none;
                    width: 75%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    margin-bottom: 9%;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: $shadow;
                }
                .video-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40%;
                    border-radius: 10px;
                    text-align: center;
                    div {
                        width: 100%;
                        padding: 0;
                        h3 {
                            font-family: $font;
                            font-weight: normal;
                            margin: 0;
                            font-size: 14px;
                        }
    
                        span {
                        font-family: $font;
                        font-size: 10px;
                        color: #86878B;
                        word-wrap: break-word;
                        margin: 0;
                        width: 100%;
                        }
                    }
    
                    #video-input {
                        width: 100%;
                        border-radius: 10px;
                        height: 140px;
                        background-color: black;
                        color: white;
                        font-family: $font;
                        font-weight: normal;
                        cursor: pointer;
                        &::-webkit-file-upload-button {
                        visibility: hidden;
                        }
                    }
    
                    #video-uploaded{
                        width: 100%;
                        height: 120px;
                        border-radius: 10px;
                        background-color: black;
                        cursor: pointer;
                    }
                }
            }
            }
            .buttons {
                margin-top: 10%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
    
                button {
                    border-radius: 10px;
                    padding: 10px 35px;
                    font-family: $font;
                    font-weight: normal;
                    font-size: 16px;
                    cursor: pointer;
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                    margin: 0px 10px;
                }
    
                #submit-button {
                    background-color: black;
                    color: $secondary;
                    border: 3px solid black;
                }
    
                #reset-button {
                    background-color: $secondary;
                    color: black;
                    border: 3px solid black;
                }
            }
        }
    }
}