@import '../../utils/variables';

.ResetPassword {
    display: flex;
    width: 100%;
    background-color: $secondary;
    height: 100vh;
    flex-direction: row; // Set row for desktop view by default

    .design {
        order: 1;
        flex: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $primary;

        .logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;

            img {
                width: 30%;
            }

            h2 {
                color: $secondary;
                margin: 0;
                margin-top: 10px;
            }
        }

        img {
            width: 50%;
        }

        h3 {
            width: 50%;
            text-align: center;
            color: $secondary;
            font-family: $font;
            font-weight: normal;
        }

        button {
            background-color: $secondary;
            font-family: $font;
            color: $primary;
            border: none;
            border-radius: 5px;
            padding: 5px 30px;
            cursor: pointer;
        }
    }

    .password-form {
        order: 2;
        flex: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        h1 {
            margin: 5% 0%;
        }
        
        .remaining-time{
            display: flex;
            color: $primary;
            margin: 0;
            margin-bottom: 5%;
            font-size: 20px;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 40%;

            label {
                margin: 2% 0%;
                color: $primary;
                font-size: 24px;
            }

            .password-input {
                width: 100%;
                position: relative;
                display: flex;
                margin: 5% 0%;

                input {
                    width: 100%;
                    background-color: white;
                    border: none;
                    border-radius: 10px;
                    padding: 14px;
                    padding-left: 35px;
                    font-family: $font;
                    font-weight: bold;
                }

                .fa-eye, .fa-eye-slash {
                    position: absolute;
                    left: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }

            #checkbox {
                margin: 10% 0%;
            }
        }

        button {
            margin-top: 2%;
            background-color: $primary;
            color: $secondary;
            border: none;
            border-radius: 10px;
            padding: 10px 30px;
            font-size: 20px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 768px) {
    .ResetPassword {
        flex-direction: column; // Change to column for mobile view

        .design {
            flex: 50%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: $primary;

            .logo {
                margin-top: 5%;
                img {
                    width: 50%;
                }
            }

            img {
                width: 75%;
            }

            h3 {
                width: 78%;
                font-size: 13px;
            }

            button {
                padding: 5px 30px;
            }
        }

        .password-form {
            flex: 50%;
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            h1 {
                font-size: 24px;
            }

            .remaining-time{
                font-size: 14px;
            }

            form {
                width: 80%;
                flex-direction: column;

                label {
                    font-size: 14px;
                }

                .password-input {
                    margin: 5% 0%;
                    input {
                        width: 100%;
                    }
                }

                #checkbox {
                    margin: 10% 0%;
                }
            }

            button {
                margin-bottom: 5%;
            }
        }
    }
}
