@import './variables';

.HomeNavbar{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 1%;
    margin: 1.5% 0%;
    .logo{
        display: flex;
        margin-left: auto;
        margin-right: 5%;
        color: $primary;
        font-size: 24px;
    }

    a{
        text-decoration: none;
        color: black;
        font-size: 17px;
        transition: background-color 0.6s ease-in-out;
        padding: 10px 15px;
        border-radius: 10px;

        &:hover{
            background-color: rgba($color: #2478DC, $alpha: 0.25);
            border-radius: 10px;
        }

        &:last-child{
            margin-left: 5%;
        }

        &.active{
            background-color: $primary;
            color: $secondary;
            border-radius: 10px;
        }
    }

    button{
        text-decoration: none;
        color: black;
        font-size: 17px;
        transition: background-color 0.6s ease-in-out;
        padding: 10px 15px;
        border-radius: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-left: 5%;
        
        &:hover{
            background-color: rgba($color: #2478DC, $alpha: 0.25);
            border-radius: 10px;
        }
    }
}

.HomeNavbar-mobile{
    display: none;
}

@media screen and (max-width:768px) {
    .HomeNavbar{
        display: none;
    }

    .HomeNavbar-mobile{
        display: flex;
        width: 100%;
        
        #navbar-click{
            position: fixed;
            top: 5%;
            right: 5%;
            background-color: transparent;
            border: none;
            z-index: 999;
            svg{
                font-size: 24px;
            }
        }

        .HomeNavbar-mobile-main{
            position: fixed;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100vh;
            background-color: white;
            z-index: 5;

            div{
                display: flex;
                flex-direction: column;
                width: 90%;
                margin-bottom: auto;
                margin-top: 30%;

                a{
                    margin: 5% 0%;
                    text-decoration: none;
                    color: black;
                }

                .auth-buttons{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    button{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $primary;
                        color: $secondary;
                        width: 50%;
                        padding: 5%;
                        border-radius: 10px;
                        border: none;
                        font-size: 16px;
                    }

                    a{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $primary;
                        width: 50%;
                        padding: 5% 0%;
                        border-radius: 10px;
                        border: none;
                        margin: 5% 0%;
                        color: $secondary;
                        font-size: 16px;
                    }

                    #create-account{
                        background-color: transparent;
                        border: 3px solid $primary;
                        color: $primary;
                    }
                }
            }
        }
    }
}
