@import '../utils/variables';

.auth {
    display: flex;
    flex: 100%;
    height: 100vh;
    
    .design {
        flex: 50%;
        width: 100Ù ;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $primary;
    
        .logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            
            img {
                width: 30%;
                border-radius: 0;
            }
            
            h2 {
                color: $secondary;
                margin: 0;
                margin-top: 10px;
            }
        }
    
        img {
            width: 30%;
            border-radius: 50px;
            transform: scale(1); /* Initial state: normal size */
            opacity: 1; /* Initial state: fully visible */
            transition: transform 0.45s ease, opacity 0.6s ease; /* Smooth animation for scaling and fading */
        }
    
        img.animate {
            transform: scale(1.2); /* Slightly larger when transitioning */
            opacity: 0; /* Fades out during the transition */
        }
    
        h3 {
            width: 50%;
            text-align: center;
            color: $secondary;
            font-family: $font;
            font-weight: normal;
        }
    
        button {
            background-color: $secondary;
            font-family: $font;
            color: $primary;
            border: none;
            border-radius: 5px;
            padding: 5px 30px;
            cursor: pointer;
        }

        span{
            text-align: center;
            margin-top: 20px;
            width: 50%;
            color: $secondary;
        }
    }    

    .auth-container{
        display: flex;
        flex: 50%;
        position: relative;
        overflow: hidden;
        .mobile-logo{
            display: none;
        }
        .hidden-login{
            display: none;
        }

        .login-container {
            flex: 50%;
            background-color: $secondary;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            animation: slideIn 1s forwards;
            h1{
                font-family: $font;
                font-weight: normal;
            }
            form{
                display: flex;
                flex-direction: column;
                direction: rtl;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                width: 100%;
                label{
                    text-align: right;
                    font-family: $font;
                    color: $primary;
                    margin-bottom: 10px;
                    width: 40%;
                }

                input{
                    width: 40%;
                    background-color: white;
                    border: none;
                    color: darkslategray;
                    border-radius: 10px;
                    padding: 14px;
                    font-family: $font;
                    font-weight: bold;
                    
                    &::placeholder{
                        color: #C2C2C2;
                        font-family: $font;
                        font-weight: normal;
                    }
                }

                .password-input{
                    position: relative;
                    width: 43%;
                    display: flex;

                    input{
                        width: 100%;
                        background-color: white;
                        border: none;
                        color: darkslategray;
                        border-radius: 10px;
                        padding: 14px;
                        font-family: $font;
                        font-weight: bold; 
                        padding-left: 35px;
                    }

                    .fa-eye, .fa-eye-slash {
                        position: absolute;
                        left: 10px; /* Adjust according to your design */
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }

                .forget-pwd{
                    display: flex;
                    align-items: center;
                    width: auto;
                    margin: 5px;
                    margin-bottom: 30px;
                    width: 40%;
                    .rememberme{
                        width: auto;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        margin-left: auto;
                        label{
                            font-family: $font;
                            font-weight: normal;
                            font-size: 10px;
                            margin: 0;
                            width: auto;
                            font-size: 11.5px;
                            cursor: pointer;
                        }
                        #checkbox{
                            appearance: none;
                            width: 1px !important;
                            height: 1px !important;
                            background-color: white; 
                            padding: 7.5px;
                            cursor: pointer;
                            border-radius: 3px;
                            &:checked{
                                background-color: $primary;
                                position: relative;                            
                            }
                            &:checked::before{
                                content: "";
                                position: absolute;
                                top: 7%;
                                left: 35%;
                                width: 3px;
                                height: 8px;
                                border: solid white;
                                border-width: 0 2px 2px 0;
                                transform: rotate(45deg);
                            }
                        }
                    }
                    
                    a{
                        color: $primary;
                        text-decoration: none;
                        font-family: $font;
                        font-size: 10px;
                        margin-right: auto;
                        font-size: 11.5px;
                    }
                }

                button{
                    margin-top: 10px;
                    padding: 15px;
                    width: 30%;
                    font-family: $font;
                    font-weight: normal;
                    cursor: pointer;
                    border-radius: 10px;
                    border: none;
                    font-size: 18px;
                }

                #submit-login{
                    background-color: $primary;
                    color: $secondary;
                }

                #create{
                    color: $primary;
                    background-color: $secondary;
                    border: 3px solid $primary;
                }
            }
        }
        .hidden-signup{
            display: none;
        }

        .hidden-reset{
            display: none;
        }
        .signup-container{
            flex: 50%;
            background-color: $secondary;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            animation: slideIn 1s forwards;
            
            span{
                text-align: center;
                color: red;
                margin: 2.5%;
            }
            h1{
                font-family: $font;
                font-weight: normal;
                margin: 0;
                margin-bottom: 20px;
                text-align: center;
            }
            .dots {
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 20px;
                .dot {
                    width: 4px;
                    height: 4px;
                    background-color: #A1CCFF;
                    border-radius: 50%;
        
                    &.active {
                        background-color: $primary;

                    }
                }
            }

            .page1{
                display: flex;
                flex-direction: column;
                width: 100%;
                form{
                    display: flex;
                    flex-direction: column;
                    direction: rtl;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    width: 100%;
                    label{
                        text-align: right;
                        font-family: $font;
                        color: $primary;
                        margin-bottom: 10px;
                        width: 40%;
                    }
    
                    input{
                        width: 40%;
                        background-color: white;
                        border: none;
                        color: darkslategray;
                        border-radius: 10px;
                        padding: 14px;
                        font-family: $font;
                        font-weight: bold;
                        
                        &::placeholder{
                            color: #C2C2C2;
                            font-family: $font;
                            font-weight: normal;
                        }
                    }
    
                    .password-input{
                        position: relative;
                        width: 44%;
                        display: flex;
    
                        input{
                            width: 100%;
                            background-color: white;
                            border: none;
                            color: darkslategray;
                            border-radius: 10px;
                            padding: 14px;
                            font-family: $font;
                            font-weight: bold; 
                            padding-left: 35px;
                        }
    
                        .fa-eye, .fa-eye-slash {
                            position: absolute;
                            left: 10px; /* Adjust according to your design */
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                        }
                    }
    
                    button{
                        margin-top: 10px;
                        padding: 15px;
                        width: 30%;
                        font-family: $font;
                        font-weight: normal;
                        cursor: pointer;
                        border-radius: 10px;
                        border: none;
                        font-size: 18px;
                    }
    
                    #submit-signup{
                        background-color: $primary;
                        color: $secondary;
                        margin-top: 50px;
                    }

                    #submit-reset{
                        background-color: $primary;
                        color: $secondary;
                        margin-top: 50px;
                    }
    
                    #login{
                        color: $primary;
                        background-color: $secondary;
                        border: 3px solid $primary;
                    }

                    .emailSent{
                        display: flex;
                        width: 45%;
                        font-size: 16px;
                        text-align: center;
                        margin-top: 5%;
                    }
                }
            }

            .page2{
                display: flex;
                flex-direction: column;
                width: 100%;
                animation: anotherSlideIn 0.5s forwards;
                div{
                    display: flex;
                    flex-direction: column;
                    direction: rtl;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    width: 100%;
                    label{
                        text-align: right;
                        font-family: $font;
                        color: $primary;
                        margin-bottom: 10px;
                        width: 40%;
                    }
    
                    input{
                        width: 40%;
                        background-color: white;
                        border: none;
                        color: darkslategray;
                        border-radius: 10px;
                        padding: 14px;
                        font-family: $font;
                        font-weight: bold;
                        
                        &::placeholder{
                            color: #C2C2C2;
                            font-family: $font;
                            font-weight: normal;
                        }
                    }
    
                    button{
                        margin-top: 10px;
                        padding: 15px;
                        width: 30%;
                        font-family: $font;
                        font-weight: normal;
                        cursor: pointer;
                        border-radius: 10px;
                        border: none;
                        font-size: 18px;
                    }
    
                    #final-submit{
                        background-color: $primary;
                        color: $secondary;
                        margin-top: 100px;
                    }
                }
            }   
            
        }
    }
    
}

@media screen and (max-width: 768px) {
    .auth {
        display: flex;
                
        .design {
            display: none;
        }
    
        .auth-container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 100%;
            position: relative;
            overflow: hidden;

            .mobile-logo{
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 20%;
                width: 50%;
            }
        
            .hidden-login{
                display: none;
            }
    
            .login-container {
                flex: 100%;
                width: 100%;
                background-color: $secondary;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                animation: slideIn 1s forwards;
                margin-bottom: 40%;
                h1{
                    font-family: $font;
                    font-weight: normal;
                }

                .design-mobile{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin: 5% 0%;
                    width: 100%;
                    span{
                        text-align: center;
                        width: 90%;
                        font-size: 16px;
                    }

                    button {
                        background-color: $primary;
                        font-family: $font;
                        color: $secondary;
                        border: none;
                        border-radius: 5px;
                        padding: 5px 30px;
                        cursor: pointer;
                    }
                }

                form{
                    display: flex;
                    flex-direction: column;
                    direction: rtl;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;
                    width: 100%;
                    label{
                        text-align: right;
                        font-family: $font;
                        color: $primary;
                        margin-bottom: 10px;
                        width: 80%;
                    }
    
                    input{
                        width: 80%;
                        background-color: white;
                        border: none;
                        color: darkslategray;
                        border-radius: 10px;
                        padding: 14px;
                        font-family: $font;
                        font-weight: bold;
                        
                        &::placeholder{
                            color: #C2C2C2;
                            font-family: $font;
                            font-weight: normal;
                        }
                    }
    
                    .password-input{
                        position: relative;
                        width: 86.5%;
                        display: flex;
    
                        input{
                            width: 100%;
                            background-color: white;
                            border: none;
                            color: darkslategray;
                            border-radius: 10px;
                            padding: 14px;
                            font-family: $font;
                            font-weight: bold; 
                            padding-left: 35px;
                        }
    
                        .fa-eye, .fa-eye-slash {
                            position: absolute;
                            left: 10px; /* Adjust according to your design */
                            top: 50%;
                            transform: translateY(-50%);
                            cursor: pointer;
                        }
                    }
    
                    .forget-pwd{
                        display: flex;
                        align-items: center;
                        width: auto;
                        margin: 5px;
                        margin-bottom: 30px;
                        width: 80%;
                        .rememberme{
                            width: auto;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            margin-left: auto;
                            label{
                                font-family: $font;
                                font-weight: normal;
                                font-size: 10px;
                                margin: 0;
                                width: auto;
                                font-size: 11.5px;
                                cursor: pointer;
                            }
                            #checkbox{
                                appearance: none;
                                width: 1px !important;
                                height: 1px !important;
                                background-color: white; 
                                padding: 7.5px;
                                cursor: pointer;
                                border-radius: 3px;
                                &:checked{
                                    background-color: $primary;
                                    position: relative;                            
                                }
                                &:checked::before{
                                    content: "";
                                    position: absolute;
                                    top: 7%;
                                    left: 35%;
                                    width: 3px;
                                    height: 8px;
                                    border: solid white;
                                    border-width: 0 2px 2px 0;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                        
                        a{
                            color: $primary;
                            text-decoration: none;
                            font-family: $font;
                            font-size: 10px;
                            margin-right: auto;
                            font-size: 11.5px;
                        }
                    }
    
                    button{
                        margin-top: 10px;
                        padding: 15px;
                        width: 50%;
                        font-family: $font;
                        font-weight: normal;
                        cursor: pointer;
                        border-radius: 10px;
                        border: none;
                        font-size: 18px;
                    }
    
                    #submit-login{
                        background-color: $primary;
                        color: $secondary;
                    }
    
                    #create{
                        color: $primary;
                        background-color: $secondary;
                        border: 3px solid $primary;
                    }
                }
            }
            .hidden-signup{
                display: none;
            }
            .signup-container{
                flex: 100%;
                width: 100%;
                background-color: $secondary;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                animation: slideIn 1s forwards;
                margin-bottom: 40%;

                h1{
                    font-family: $font;
                    font-weight: normal;
                    margin: 0;
                    margin-bottom: 20px;
                }
                .dots {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 20px;
                    .dot {
                        width: 4px;
                        height: 4px;
                        background-color: #A1CCFF;
                        border-radius: 50%;
            
                        &.active {
                            background-color: $primary;
    
                        }
                    }
                }
    
                .page1{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    form{
                        display: flex;
                        flex-direction: column;
                        direction: rtl;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                        width: 100%;
                        label{
                            text-align: right;
                            font-family: $font;
                            color: $primary;
                            margin-bottom: 10px;
                            width: 80%;
                        }
        
                        input{
                            width: 80%;
                            background-color: white;
                            border: none;
                            color: darkslategray;
                            border-radius: 10px;
                            padding: 14px;
                            font-family: $font;
                            font-weight: bold;
                            
                            &::placeholder{
                                color: #C2C2C2;
                                font-family: $font;
                                font-weight: normal;
                            }
                        }
        
                        .password-input{
                            position: relative;
                            width: 86.5%;
                            display: flex;
        
                            input{
                                width: 100%;
                                background-color: white;
                                border: none;
                                color: darkslategray;
                                border-radius: 10px;
                                padding: 14px;
                                font-family: $font;
                                font-weight: bold; 
                                padding-left: 35px;
                            }
        
                            .fa-eye, .fa-eye-slash {
                                position: absolute;
                                left: 10px; /* Adjust according to your design */
                                top: 50%;
                                transform: translateY(-50%);
                                cursor: pointer;
                            }
                        }
        
                        button{
                            margin-top: 10px;
                            padding: 15px;
                            width: 50%;
                            font-family: $font;
                            font-weight: normal;
                            cursor: pointer;
                            border-radius: 10px;
                            border: none;
                            font-size: 18px;
                        }
        
                        #submit-signup{
                            background-color: $primary;
                            color: $secondary;
                            margin-top: 50px;
                        }
        
                        #login{
                            color: $primary;
                            background-color: $secondary;
                            border: 3px solid $primary;
                        }

                        .emailSent{
                            display: flex;
                            width: 75%;
                            font-size: 16px;
                            text-align: center;
                            margin-top: 5%;
                        }
                    }
                }
    
                .page2{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    animation: anotherSlideIn 0.5s forwards;
                    div{
                        display: flex;
                        flex-direction: column;
                        direction: rtl;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                        width: 100%;
                        label{
                            text-align: right;
                            font-family: $font;
                            color: $primary;
                            margin-bottom: 10px;
                            width: 80%;
                        }
        
                        input{
                            width: 80%;
                            background-color: white;
                            border: none;
                            color: darkslategray;
                            border-radius: 10px;
                            padding: 14px;
                            font-family: $font;
                            font-weight: bold;
                            
                            &::placeholder{
                                color: #C2C2C2;
                                font-family: $font;
                                font-weight: normal;
                            }
                        }
        
                        button{
                            margin-top: 10px;
                            padding: 15px;
                            width: 50%;
                            font-family: $font;
                            font-weight: normal;
                            cursor: pointer;
                            border-radius: 10px;
                            border: none;
                            font-size: 18px;
                        }
        
                        #final-submit{
                            background-color: $primary;
                            color: $secondary;
                            margin-top: 100px;
                        }
                    }
                }   
                
            }

            .hidden-reset{
                display: none;
            }
        }
    }
}

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0%);
    }
}

@keyframes anotherSlideIn {
    from {
        opacity: 0;
        transform: translateX(100%);
    }
    to {
        opacity: 1;
        transform: translateX(0%);
    }
}