@import '../../utils/variables';

.deleteAccount{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    
    .verifyIdentity{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60%;
        
        h1{
            color: $delete;
            font-size: 48px;
        }

        img{    
            width: 25%;
        }
        
        form{
            display: flex;
            flex-direction: column;
            direction: rtl;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            width: 100%;

            span{
                margin: 2% 0%;
                color: $delete;
            }

            label{
                text-align: right;
                font-family: $font;
                color: $primary;
                margin-bottom: 10px;
                width: 40%;
            }
    
            input{
                width: 40%;
                background-color: white;
                border: none;
                color: darkslategray;
                border-radius: 10px;
                padding: 14px;
                font-family: $font;
                font-weight: bold;
                
                &::placeholder{
                    color: #C2C2C2;
                    font-family: $font;
                    font-weight: normal;
                }
            }
    
            .password-input{
                position: relative;
                width: 42.5%;
                display: flex;
    
                input{
                    width: 100%;
                    background-color: white;
                    border: none;
                    color: darkslategray;
                    border-radius: 10px;
                    padding: 14px;
                    font-family: $font;
                    font-weight: bold; 
                    padding-left: 35px;
                }
    
                .fa-eye, .fa-eye-slash {
                    position: absolute;
                    left: 10px; /* Adjust according to your design */
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                }
            }
        }
        #submit{
            background-color: $primary;
            color: $secondary;
            margin-top: 5%;
            padding: 15px;
            width: 30%;
            font-family: $font;
            font-weight: normal;
            cursor: pointer;
            border-radius: 10px;
            border: none;
            font-size: 18px;
        }
    }

    .why {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
    
        .image-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary;
            
            img {
                width: 15%;
                border-radius: 20px;
            }
        }
    
        form {
            width: 15%;
            margin: 1% 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            pointer-events: auto;  // default state for pointer events
    
            &.disable-pointer-events {
                pointer-events: none;  // disable interactions when button is moving
            }
    
            div {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                margin: 2.5% 0;
                width: 100%;
            }
    
            textarea {
                width: 80%;
                background-color: white;
                border: none;
                color: darkslategray;
                border-radius: 10px;
                padding: 14px;
                font-family: $font;
                font-weight: bold;
                resize: vertical;
                min-height: 10vh;
                margin-top: 5%;
    
                &::placeholder {
                    color: #C2C2C2;
                    font-family: $font;
                    font-weight: normal;
                }
            }
        }
    
        #confirm {
            z-index: 999;
            position: relative;
            transition: transform 0.2s ease;
            background-color: $delete;
            color: $secondary;
            margin-top: 2.5%;
            padding: 15px;
            font-family: $font;
            font-weight: normal;
            cursor: pointer;
            border-radius: 10px;
            width: 10%;
            border: none;
            font-size: 18px;
            
            &:hover {
                animation: moveButton 0.8s ease-in-out forwards;
            }
        }
    } 
}

@keyframes moveButton {
    0% { transform: translateX(0); }
    25% { transform: translateX(-20px); }
    50% { transform: translateX(20px); }
    75% { transform: translateX(-15px); }
    100% { transform: translateX(0); }
}

@media screen and (max-width: 768px){
    .deleteAccount{
        .verifyIdentity{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 90%;
            
            h1{
                color: $delete;
                font-size: 26px;
            }
    
            img{    
                width: 75%;
            }
            
            form{
                display: flex;
                flex-direction: column;
                direction: rtl;
                align-items: center;
                justify-content: center;
                margin-top: 20px;
                width: 90%;
    
                span{
                    margin: 5% 0%;
                    color: $delete;
                    text-align: center;
                }
    
                label{
                    text-align: right;
                    font-family: $font;
                    color: $primary;
                    margin-bottom: 10px;
                    width: 100%;
                }
        
                input{
                    width: 90%;
                    background-color: white;
                    border: none;
                    color: darkslategray;
                    border-radius: 10px;
                    padding: 14px;
                    font-family: $font;
                    font-weight: bold;
                    
                    &::placeholder{
                        color: #C2C2C2;
                        font-family: $font;
                        font-weight: normal;
                    }
                }
        
                .password-input{
                    position: relative;
                    width: 100%;
                    display: flex;
        
                    input{
                        width: 100%;
                        background-color: white;
                        border: none;
                        color: darkslategray;
                        border-radius: 10px;
                        padding: 14px;
                        font-family: $font;
                        font-weight: bold; 
                        padding-left: 35px;
                    }
        
                    .fa-eye, .fa-eye-slash {
                        position: absolute;
                        left: 10px; /* Adjust according to your design */
                        top: 50%;
                        transform: translateY(-50%);
                        cursor: pointer;
                    }
                }
            }
            #submit{
                background-color: $primary;
                color: $secondary;
                margin-top: 10%;
                padding: 15px;
                width: 75%;
                font-family: $font;
                font-weight: normal;
                cursor: pointer;
                border-radius: 10px;
                border: none;
                font-size: 18px;
            }
        }
    
        .why{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            h1{
                text-align: center;
                font-size: 26px;
            }

            .image-container{
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary;
                img{
                    width: 50%;
                    border-radius: 20px;
                }
            }
            
    
            form{
                width: 90%;
                margin: 1% 0%;
                display: flex;
                flex-direction: column;
                align-items: center;
    
                div {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    margin: 3% 0;
                    width: 100%;

                    label{
                        font-size: 18px;
                    }
                }
    
                textarea{
                    width: 80%;
                    background-color: white;
                    border: none;
                    color: darkslategray;
                    border-radius: 10px;
                    padding: 14px;
                    font-family: $font;
                    font-weight: bold;
                    resize: vertical;
                    min-height: 10vh;
                    margin-top: 5%;
                    &::placeholder{
                        color: #C2C2C2;
                        font-family: $font;
                        font-weight: normal;
                    }
                }
            }

            #confirm {
                z-index: 999;
                position: relative;
                transition: transform 0.2s ease;
                background-color: $delete;
                color: $secondary;
                margin-top: 2.5%;
                padding: 15px;
                font-family: $font;
                font-weight: normal;
                cursor: pointer;
                border-radius: 10px;
                width: 50%;
                border: none;
                font-size: 18px;
    
                &:hover {
                    animation: moveButton 0.8s ease-in-out forwards;
                }
            }
        }
    }
}