@import '../utils/variables';

.ChapterManagement {
    background-color: $secondary;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .navigation{
        display: flex;
        align-items: center;
        width: auto;
        position: absolute;
        top: 5%;
        left: 2.5%;
        button{
            cursor: pointer;
            border: none;
            background-color: transparent;
        }
    
        #navigate {
            display: flex;
            margin-right: auto;
        }
    }

    h1 {
        font-family: $font;
        font-weight: normal;
    }
    width: 100%;

    form {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;

        .main {
        display: flex;
        width: 100%;
        margin-top: 2.5%;

        .textual {
            display: flex;
            flex-direction: column;
            flex: 50%;
            align-items: center;
            padding: 20px 0px;

            div {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;

            label {
                font-family: $font;
                font-weight: normal;
                direction: rtl;
                text-align: right;
                margin: 0px 0px 15px 0px;
                width: 100%;
                margin-right: 25%;
            }

            input {
                border: none;
                width: 43%;
                min-height: 25px;
                border-radius: 10px;
                direction: rtl;
                padding: 10px;
                font-family: $font;
                margin-bottom: 9%;
                font-size: 14px;
                &::placeholder {
                color: #A7A7A7;
                font-family: $font;
                font-weight: normal;
                }
                box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
            }

            textarea {
                position: relative;
                border: none;
                width: 40%;
                min-height: 140px;
                border-radius: 10px;
                direction: rtl;
                padding: 20px;
                font-family: $font;
                resize: none;
                font-size: 14px;
                &::placeholder {
                color: #A7A7A7;
                font-family: $font;
                font-weight: normal;
                }
                box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
            }

            .char-count {
                position: absolute;
                bottom: 10px;
                left: 30%;
                width: auto;
                font-size: 12px;
                color: #86878B;
                font-family: $font;
            }
            }
        }

        .line {
            background-color: black;
            width: 0.1rem;
            height: 420px;
            flex: none;
            margin: 0 20px;
        }

        .media {
            flex: 50%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0px;

            label {
            font-family: $font;
            font-weight: normal;
            direction: rtl;
            text-align: right;
            margin: 0px 0px 15px 0px;
            width: auto;
            margin-right: 12%;
            margin-left: auto;
            }

            .image-container {
            background-color: white;
            padding: 20px 10px;
            display: flex;
            align-items: center;
            box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
            width: 40%;
            min-height: 30%;
            border-radius: 10px;
            text-align: right;
            margin-left: auto;
            margin-right: 21%;
            div {
                width: 60%;
                padding: 0px 10px 0px 0px;
                h3 {
                font-family: $font;
                font-weight: normal;
                margin: 0;
                font-size: 14px;
                }

                span {
                font-family: $font;
                font-size: 10px;
                color: #86878B;
                word-wrap: break-word;
                margin: 0;
                width: 100%;
                }
            }

            #image-input {
                width: 100%;
                border-radius: 10px;
                height: 120px;
                background-color: black;
                color: white;
                font-family: $font;
                font-weight: normal;
                cursor: pointer;
                &::-webkit-file-upload-button {
                visibility: hidden;
                }
            }

            #image-uploaded{
                width: 100%;
                height: 120px;
                border-radius: 10px;
                background-color: black;
                cursor: pointer;

            }
            }
        }
        }

        .buttons {
        margin-top: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        button {
            border-radius: 10px;
            padding: 10px 35px;
            font-family: $font;
            font-weight: normal;
            font-size: 16px;
            cursor: pointer;
            box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
            margin: 0px 30px;
        }

        #submit-button {
            background-color: black;
            color: $secondary;
            border: 3px solid black;
        }

        #reset-button {
            background-color: $secondary;
            color: black;
            border: 3px solid black;
        }
        }
    }
}

@media screen and (max-width:768px) {
    .ChapterManagement {
        background-color: $secondary;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20% 0% 10% 0%;
        .navigation{
            display: flex;
            align-items: center;
            width: auto;
            position: absolute;
            top: 7.5%;
            left: 2.5%;
            button{
                cursor: pointer;
                border: none;
                background-color: transparent;
            }
        
            #navigate {
                display: flex;
                margin-right: auto;
            }
        }

        h1 {
            font-family: $font;
            font-weight: normal;
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            flex-direction: column;

            .main {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-top: 2.5%;

            .textual {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 0px;

                div {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 80%;

                label {
                    font-family: $font;
                    font-weight: normal;
                    direction: rtl;
                    text-align: right;
                    margin: 0px 0px 15px 0px;
                    width: 100%;
                    margin-right: 25%;
                }

                input {
                    border: none;
                    width: 75%;
                    min-height: 25px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 10px;
                    font-family: $font;
                    margin-bottom: 9%;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                }

                textarea {
                    position: relative;
                    border: none;
                    width: 68%;
                    min-height: 140px;
                    border-radius: 10px;
                    direction: rtl;
                    padding: 20px;
                    font-family: $font;
                    resize: none;
                    font-size: 14px;
                    &::placeholder {
                    color: #A7A7A7;
                    font-family: $font;
                    font-weight: normal;
                    }
                    box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                }

                .char-count {
                    position: absolute;
                    bottom: 10px;
                    left: 10%;
                    width: auto;
                    font-size: 12px;
                    color: #86878B;
                    font-family: $font;
                }
                }
            }

            .line {
                display: none;
            }

            .media {
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                label {
                font-family: $font;
                font-weight: normal;
                direction: rtl;
                text-align: right;
                margin: 0px 0px 15px 0px;
                width: auto;
                margin-right: 12%;
                margin-left: auto;
                }

                .image-container {
                background-color: white;
                padding: 20px 0px;
                display: flex;
                align-items: center;
                box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                width: 80%;
                min-height: 30%;
                border-radius: 10px;
                text-align: right;
                margin-left: auto;
                margin-right: 10%;
                div {
                    width: 60%;
                    padding: 0px 10px 0px 0px;
                    h3 {
                    font-family: $font;
                    font-weight: normal;
                    margin: 0;
                    font-size: 14px;
                    }

                    span {
                        font-family: $font;
                        font-size: 10px;
                        color: #86878B;
                        word-wrap: break-word;
                        margin: 0;
                        width: 100%;
                    }
                }

                #image-input {
                    width: 100%;
                    border-radius: 10px;
                    height: 120px;
                    background-color: black;
                    color: white;
                    font-family: $font;
                    font-weight: normal;
                    cursor: pointer;
                    &::-webkit-file-upload-button {
                    visibility: hidden;
                    }
                }

                #image-uploaded{
                    width: 100%;
                    height: 120px;
                    border-radius: 10px;
                    background-color: black;
                    cursor: pointer;

                }
                }
            }
            }

            .buttons {
            margin-top: 5%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                border-radius: 10px;
                padding: 10px 30px;
                font-family: $font;
                font-weight: normal;
                font-size: 16px;
                cursor: pointer;
                box-shadow: -3px 6px 5px 0px rgba(0, 0, 0, 0.2);
                margin: 0px 10px;
            }

            #submit-button {
                background-color: black;
                color: $secondary;
                border: 3px solid black;
            }

            #reset-button {
                background-color: $secondary;
                color: black;
                border: 3px solid black;
            }
            }
        }
    }
}