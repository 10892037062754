@import '../../utils/variables';

.Home{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .intro{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        margin: 1% 0%;

        img{
            width: 15%;
        }

        #intro-image{
            width: 50%;
        }

        button{
            color: $secondary;
            background-color: $primary;
            border: none;
            border-radius: 10px;
            cursor: pointer;
            padding: 0.5% 3.5%;
            font-size: 24px;
        }
    }

    .home-about-us{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 95%;
        flex-direction: column;
        margin: 2% 0%;
        img{
            width: 100%;
        }

        .description{
            display: flex;
            justify-content: center;
            width: 100%;
            margin: 1% 0%;
            height: 70vh;
            .ppf{
                width: 50%;
                background-image: url('../../../utils/ppf.png');
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
            }

            ul{
                margin-top: 2.5%;
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 5%;
                li{
                    font-size: 42px;
                    width: 80%;
                }

                button{
                    background-color: black;
                    color: $secondary;
                    width: 25%;
                    margin: 0% auto;
                    margin-top: auto;
                    border-radius: 5px;
                    border: none;
                    padding: 10px 0px;
                    font-size: 21px;
                    cursor: pointer;
                }
            }
        }
    }

    .availability{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        
        .mobdesk{
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
            margin-top: 10%;
            
            div{
                position: absolute;
                z-index: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                top: -45%;
                width: 100%;
                gap: 50%;

                img{
                    width: 25%;
                }
            }
            

            #ground{
                width: 100%;
                z-index: 0;
            }
        }
    }
    .get-started{
        display: flex;
        width: 75%;
        align-items: center;
        justify-content: center;
        .start-mobile{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-left: auto;
            text-align: center;
            width: 50%;
            margin-right: auto;

            h1{
                width: 70%;
            }

            .start-buttons{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                button{
                    display: flex;
                    direction: ltr;
                    align-items: center;
                    background-color: transparent;
                    color: $secondary;
                    border: none;
                    border-radius: 10px;
                    padding: 10px 20px;
                    width: 32.5%;
                    margin: 2.5% 0%;
                    height: 5vh;
                    cursor: pointer;

                    img{
                        width: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        .start-desktop{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin-right: auto;
            text-align: center;
            width: 50%;
            margin-left: auto;

            h1{
                width: 65%;
            }

            .start-buttons{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                button{
                    background-color: black;
                    color: $secondary;
                    border: none;
                    border-radius: 10px;
                    padding: 15px 20px;
                    width: 100%;
                    margin: 5% 0%;
                    height: 7vh;
                    font-size: 26px;
                    cursor: pointer;
                    
                    &:last-child{
                        background-color: $secondary;
                        color: black;
                        border: 3px solid black;
                    }
                }
            }
        }
    }

    .objectif{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 4% 0%;
        h1{
            font-family: $font;
            font-size: 48px;
        }

        h3{
            font-family: $font;
            width: 65%;
            text-align: center;
            margin-bottom: 75px;
            font-size: 32px;

        }

        .memes{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60%;
            gap: 20%;
            h1{
                width: 50%; 
                text-align:center; 
                margin-top: 60px;
                font-weight: normal;
            }

            .meme{
                display: flex;
                flex-direction: column;
                align-items: center;

                span{
                    font-family: $font;
                    color: black;
                    width: 50%;
                    font-weight: bold;
                    text-align: center;
                    margin-bottom: 20px;
                }
                img{
                    width: 100%;
                }
            }
        }

        #our-goal{
            margin-top: 2%;
            font-size: 48px;
            color: $primary;
            width: 40%;
            text-align: center;
        }
    }

    #quote{
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;
        background-color: $primary;
        width: 100%;
        h1{
            &:first-child{
                margin-top: 50px;
            }
            
            &:last-child{
                margin-bottom: 50px;
            }
            width: 50%;
            margin: 5px;
            direction: rtl;
            font-family: $font;
            font-weight: normal;
            color: $secondary;
            font-size: 56px;
            &:first-child{
                margin-top: 20px;
                margin-bottom: 50px;
            }
        }
    }

    .social-media{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 7.5%;
        h1{
            
            color: $primary;
            font-family: $font;
            margin: 0;
            margin-bottom: 15px;
            margin-top: 50px;
        }
        h3{
            color: black;
            font-family: $font;
            font-weight: normal;
            margin: 0;
            margin-bottom: 20px;
        }
        .icons{
            display: flex;
            align-items: center;
            width: 100%;
            margin-top: 20px;
            a{
                background-color: $primary;
                padding: 25px;
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 10%;
                margin-right: 60px;
                cursor: pointer;
                &:first-child{
                    margin-right: 0px;
                }

                svg{
                    font-size: 48px;
                }
            }
        }
    }
}

@media screen and (max-width:768px) {
    .Home{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
        
        .intro{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin: 2% 0%;
            margin-top: 15%;

            h1{
                font-size: 16px;
                text-align: center;
            }

            img{
                width: 50%;
            }
    
            #intro-image{
                width: 90%;
            }
    
            button{
                color: $secondary;
                background-color: $primary;
                border: none;
                border-radius: 10px;
                padding: 2% 5%;
                font-size: 20px;
                margin-top: 2%;
            }
        }
    
        .home-about-us{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 95%;
            flex-direction: column;
            margin: 2% 0%;
            h1{
                text-align: center;
                font-size: 24px;
            }
            img{
                width: 100%;
            }
    
            .description{
                display: flex;
                justify-content: center;
                width: 100%;
                margin: 1% 0%;
                flex-direction: column-reverse;
                .ppf{
                    width: 100%;
                    height: 100vh;
                    background-image: url('../../../utils/ppf.png');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
    
                ul{
                    margin-top: 2.5%;
                    width: 87.5%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    
                    li{
                        font-size: 16px;
                        width: 100%;
                        padding: 0;
                        margin: 0;
                    }
    
                    button{
                        background-color: black;
                        color: $secondary;
                        width: 50%;
                        margin: 0% 0%;
                        margin-left: 15%;
                        margin-top: 10%;
                        border-radius: 5px;
                        border: none;
                        padding: 10px 0px;
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
            }
        }
    
        .availability{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 10%;

            h1{
                text-align: center;
                font-size: 24px;
                width: 80%;
            }

            .mobdesk{
                width: 100%;
                display: flex;
                align-items: center;
                position: relative;
                margin-top: 10%;
                
                div{
                    position: absolute;
                    z-index: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: -60%;
                    width: 100%;
                    gap: 40%;
    
                    img{
                        width: 30%;
                    }
                }
                
    
                #ground{
                    width: 100%;
                    z-index: 0;
                }
            }
        }
        .get-started{
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .start-mobile{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-left: auto;
                text-align: center;
                width: 100%;
                margin-right: auto;
    
                h1{
                    font-size: 16px;
                    width: 100%;
                }
    
                .start-buttons{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 75%;
                    button{
                        display: flex;
                        direction: ltr;
                        align-items: center;
                        background-color: black;
                        color: $secondary;
                        border: none;
                        border-radius: 10px;
                        padding: 10px 5px;
                        width: 60%;
                        margin: 5% 0%;
                        height: 8vh;
                        cursor: pointer;
                        
                        img{
                            width: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
    
            .start-desktop{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-right: auto;
                text-align: center;
                width: 100%;
                margin-left: auto;
    
                h1{
                    width: 80%;
                    font-size: 16px;
                }
    
                .start-buttons{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    width: 75%;
                    button{
                        background-color: black;
                        color: $secondary;
                        border: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 10px;
                        padding: 15px 20px;
                        width: 60%;
                        margin: 5% 0%;
                        height: 7vh;
                        font-size: 18px;
                        cursor: pointer;
                        
                        &:last-child{
                            background-color: $secondary;
                            color: black;
                            border: 3px solid black;
                        }
                    }
                }
            }
        }
    
        .objectif{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 4% 0%;
            h1{
                font-family: $font;
                font-size: 36px;
            }
    
            h3{
                font-family: $font;
                width: 90%;
                text-align: center;
                margin-bottom: 5%;
                font-size: 18px;
            }
    
            .memes{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 80%;
                gap: 5% 0%;
    
                .meme{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin: 5% 0%;

                    span{
                        font-family: $font;
                        color: black;
                        width: 90%;
                        font-weight: bold;
                        text-align: center;
                        margin: 4% 0%;
                        font-size: 16px;
                    }
                    img{
                        width: 100%;
                    }
                }
            }
    
            #our-goal{
                margin-top: 2%;
                font-size: 30px;
                color: $primary;
                width: 80%;
                text-align: center;
            }
        }
    
        #quote{
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            background-color: $primary;
            width: 100%;
            h1{
                &:first-child{
                    margin-top: 50px;
                }
                
                &:last-child{
                    margin-bottom: 50px;
                }
                width: 90%;
                margin: 5px;
                direction: rtl;
                font-family: $font;
                font-weight: normal;
                color: $secondary;
                font-size: 28px;
                &:first-child{
                    margin-top: 20px;
                    margin-bottom: 50px;
                }
            }
        }
    
        .social-media{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 20%;
            width: 90%;
            h1{
                text-align: center;
                font-size: 24px;
                color: $primary;
                font-family: $font;
                margin: 0;
                margin-bottom: 15px;
                margin-top: 50px;
            }
            h3{
                color: black;
                font-size: 16px;
                text-align: center;
                font-family: $font;
                font-weight: normal;
                margin: 0;
                margin-bottom: 20px;
            }
            .icons{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 20px;
                a{
                    background-color: $primary;
                    padding: 15px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 7.5%;
                    margin-right: 20px;
                    cursor: pointer;
                    &:first-child{
                        margin-right: 0px;
                    }
    
                    svg{
                        font-size: 28px;
                    }
                }
            }
        }
    }    
}