@import './variables';

.subject-card{
    display: flex;
    align-items: center;
    background-color: white;
    direction: rtl;
    padding: 0;
    border-radius: 25px;
    max-width: 30%;
    box-shadow: $shadow;
    margin: 25px 0px;
    height: 14vh;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 30%;

    .card-content{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 90%;
        margin: 0% 5%;
        img{
            width: 29%;
            aspect-ratio: 5/5;
            border-radius: 15px;
            padding: 0;
            margin: 0;
        }
    
        h1{
            font-family: $font;
            width: 90%;
            text-align: right;
            font-size: 30px;
            margin-right: 5%;
        }
    }

    button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-right: auto;
        margin-left: 10px;
    }
}

.chapter-card{
    display: flex;
    align-items: center;
    background-color: white;
    direction: rtl;
    padding: 0;
    border-radius: 25px;
    max-width: 30%;
    box-shadow: $shadow;
    margin: 25px 0px;
    height: 14vh;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    flex: 1 1 30%;

    img{
        width: 26%;
        margin: 3%;
        aspect-ratio: 5/5;
        border-radius: 15px;
        padding: 0;
    }

    div{
        display: flex;
        flex-direction: column;
        h1{
            font-size: 22px;
            margin: 0;
            margin-bottom: 5px;
            max-width: 95%;
        }

        .custom-span{
            font-size: 13px;
            color: gray;
            width: 95%;
        }
    }

    button{
        background-color: transparent;
        border: none;
        cursor: pointer;
        margin-right: auto;
        margin-left: 10px;
    }

    .locked{
        font-size: 14px;
        margin: 10px auto auto 10px;
        width: 12px;
        color: $primary;

        &.animate {
            animation: popOut 0.5s ease-in-out; // Adjust duration as needed
        }
    }
}

@media screen and (max-width: 768px) {
    .subject-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 45%;
        height: 20vh;
        margin: 0;
        padding: 0;
        flex: 1 1 40%;
        margin: 15px 0px;

        .card-content{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            margin: 2%;
            order: 2;

            img{
                width: 75%;
                aspect-ratio: 5/5;
                border-radius: 20px;
                padding: 0;
                margin: 0;
            }
        
            h1{
                font-family: $font;
                width: 75%;
                text-align: center;
                font-size: 14px;
                margin: 5% 0%;                
            }
        }
        
        button{
            order: 1;
            background-color: transparent;
            border: none;
            cursor: pointer;
            margin-right: auto;
            margin-left: 10px;

            svg{
                font-size: 16px;
            }
        }
    }
    
    .chapter-card{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 100%;
        min-height: 15vh;
        max-height: 30vh;
        margin: 0;
        padding: 0;
        margin: 15px 0px;

        img{
            margin: 10px 15px 10px 15px;
            width: 30%;
            aspect-ratio: 9/9;
            border-radius: 15px;
            margin-left: 5px;
        }
    
        div{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-right: 2.5%;
            h1{
                font-size: 16px;
                margin: 0;
                margin-bottom: 5px;
            }
    
            .custom-span{
                font-size: 10px;
                color: gray;
                width: 90%;
            }
        }
    
        button{
            background-color: transparent;
            border: none;
            cursor: pointer;
            margin-right: auto;
            margin-left: 10px;
        }
    }
}   

@keyframes popOut {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.75);
    }
    100% {
        transform: scale(1);
    }
}