@import './variables';

.edumeme-scroll-card{
    display: flex;
    flex-direction: column;
    width: 80%;
    min-height: 40vh;
    border-radius: 20px;
    background-color: white;
    position: relative;
    padding: 5% 5%;

    h1{
        margin: 0;
        font-size: 26px;
    }
    h3{
        margin: 0;
        font-size: 20px;
    }

     #link{
        position: absolute;
        top: 9%;
        left: 10%;
        background-color: $primary;
        border-radius: 50%;
        padding: 2.5% 2%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg{
            color: white;
            font-size: 16px;
        }
    }

    .type-image{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15% 0% 10% 0%;
        flex-direction: column;
        span{
            width: 90%;
            font-size: 14px;
            margin-bottom: 2.5%;
        }

        img{
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    .type-video{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 5% 0% 10% 0%;
        video{
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    .interactions{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: auto;
        div{
            background-color: transparent;
            border: none;
            margin: 0% 2.5%;
            display: flex;
            align-items: center;
            gap: 7.5%;

            svg{
                font-size: 24px;
            }
            span{
                font-size: 14px;
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9998; // Keep it just below the comment section
    }    

    .mobile-open-card {
        position: fixed; // Change from 'absolute' to 'fixed'
        bottom: 0; // Align to the top of the screen
        left: 0; // Align to the left of the screen
        z-index: 9999;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 100%;
        height: 90vh;
        border: none;
        border-radius: 10px;
        overflow: hidden; // Prevent content inside from overflowing
    
        &.slide-in {
            animation: slideDown 0.7s ease forwards;
        }
    
        &.slide-out {
            animation: slideUp 0.7s ease forwards;
        }    
        
        .line {
            border: 2px solid lightgray;
            border-radius: 10px;
            width: 10%;
            margin: 0 auto; /* To center the line */
            margin-top: 5px;
        }
        

        .comments {
            flex-grow: 1; 
            width: 90%;
            overflow-y: auto;
            padding: 10px;
            .nocomments {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                text-align: center;
            }

            img{
                width: 50%;
                margin-bottom: 20px;
            }
        }

        .comment-field {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            width: 95%;
        
            input {
                display: flex;
                align-items: center;
                border-radius: 10px;
                border: none;
                padding: 10px;
                background-color: $secondary;
                width: 100%;
            }
        
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                background-color: transparent;
            }
        }
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}